import luggage from "../../assets/images/luggage.png";
import successImg from "../../assets/images/success.png";
import { Link } from "react-router-dom";
import ExtraLuggage from "./ExtraLuggage";
import { useState, useEffect } from "react";
import Loader from "../icons/Loader";
import { bookExtraLuggage, getExtraLuggage } from "../../api/api";
export default function LuggageSelection({ totalExcessBuggage, setTotalExcessBuggage, data, setProceedPaymentDts, proceedPaymentDts }) {
  const bookingId = proceedPaymentDts.bookingId;
  const [show, setShow] = useState(false);
  const [amountBreakDown, setAmountBreakeDown] = useState({
    oneWay: {
      chargeCurrency: "INR",
      chargePrice: 0,
      chargePriceWithTax: 0,
      amountBreakDown: [],
    },
    roundWay: {
      chargeCurrency: "INR",
      chargePrice: 0,
      chargePriceWithTax: 0,
      amountBreakDown: [],
    },
  });
  const [isRemoved, setIsRemoved] = useState(false);
  const [completedPaxes, setCompletedPaxes] = useState([]);
  const style = {
    wrapper: {
      maxWidth: "512px",
      minHeight: "360px",
      boxShadow: " 0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "1rem",
    },
    btn: {
      width: "196px",
      height: "48px",
      textDecoration: "none",
      border: "1px solid var(--primaryColor)",
    },
  };
  useEffect(() => {
    getExtraLuggage({
      companyCode: bookingId[0],
      documentCode: bookingId[1],
      bookingYear: bookingId[2],
      bookingSerial: bookingId[3],
    }).then((data) => {
      if (data) {
        setCompletedPaxes(data.paxLuggageList);
        setTotalExcessBuggage({
          weight: data.totalExcessBuggage.weight,
          amount: data.totalExcessBuggage.amount,
          returnWeight: data.totalExcessBuggage.returnWeight,
          returnAmount: data.totalExcessBuggage.returnAmount,
        });
      }
    });
  }, []);
  return (
    <div>
      <h1 className="fs-20px b-500">Book for Extra Luggage (Optional)</h1>
      <div className="mt-2 d-f f-spb f-col" style={style.wrapper}>
        <img src={luggage} alt="" width="100%" height="192px" />
        <div className="d-f f-col p1-5 r-gap-1-5">
          <p>Pack more memories! Secure your extra luggage effortlessly with our convenient booking options.</p>
          {totalExcessBuggage.weight + totalExcessBuggage.returnWeight === 0 && (
            <button onClick={() => setShow(true)} className="sm-m-a br-btn b-500 d-f f-a-c f-j-c primary br-r-8 pointer" style={{ ...style.btn }}>
              Extra Luggage
            </button>
          )}
        </div>
        {totalExcessBuggage.weight + totalExcessBuggage.returnWeight > 0 && <Detail completedPaxes={completedPaxes} setTotalExcessBuggage={setTotalExcessBuggage} totalExcessBuggage={totalExcessBuggage} setIsRemoved={setIsRemoved} amountBreakDown={amountBreakDown} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} data={data} setShow={setShow} />}
      </div>
      {show && (
        <div className="overlay">
          <ExtraLuggage totalExcessBuggage={totalExcessBuggage} setTotalExcessBuggage={setTotalExcessBuggage} completedPaxes={completedPaxes} setCompletedPaxes={setCompletedPaxes} count={data.noOfPax.adult} setAmountBreakeDown={setAmountBreakeDown} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} data={data} setShow={setShow} />
        </div>
      )}
      {isRemoved && <Success setIsRemoved={setIsRemoved} />}
    </div>
  );
}

function Detail({ completedPaxes, totalExcessBuggage, setTotalExcessBuggage, setIsRemoved, amountBreakDown, data, setShow }) {
  const [showOrderTray, setShowOrderTray] = useState("");
  const [removeLoader, setRemoveLoader] = useState(false);
  const [removeType, setRemoveType] = useState("");
  const chargeCurrency = data.oneWayData.chargeCurrency;
  function handleRemove(type) {
    setRemoveType(type);
    setRemoveLoader(true);
    completedPaxes.forEach((pax) => (pax[type] = 0));
    setTimeout(() => {
      bookExtraLuggage({ requestType: "", paxes: completedPaxes }).then((data) => {
        if (data) {
          setTotalExcessBuggage({
            weight: data.totalExcessBuggage.weight,
            amount: data.totalExcessBuggage.amount,
            returnWeight: data.totalExcessBuggage.returnWeight,
            returnAmount: data.totalExcessBuggage.returnAmount,
          });
          setRemoveLoader(false);
          setIsRemoved(true);
        }
      });
    }, 2000);
  }
  return (
    <div className="p-1">
      {totalExcessBuggage.weight > 0 && (
        <>
          {" "}
          <hr style={{ opacity: ".2" }} />
          <div className="d-f f-spb ptb-1 f-end">
            <div className="d-f f-col">
              <p className="primary">
                {data.oneWayData.voyageFrom} to {data.oneWayData.voyageTo}
              </p>
              {/* <p onClick={() => setShowOrderTray('one')} className="primary b-700 pointer" style={{ textDecoration: 'underline' }}>View Details</p> */}
              <p onClick={() => setShowOrderTray("one")} className="primary b-700 pointer" style={{ textDecoration: "underline" }}>
                Extra Luggage: {totalExcessBuggage.weight} kg
              </p>
              <p>
                Total:{" "}
                <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
                  {totalExcessBuggage.amount.toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}
                </span>
              </p>
            </div>
            <div className="txt-dec-u pointer p-r" onClick={() => handleRemove("weight")} style={{ minWidth: "35px", minHeight: "35px" }}>
              {removeType == "weight" && removeLoader ? <Loader color="#DF7435" /> : "Remove"}
            </div>
          </div>
        </>
      )}
      {data.round && totalExcessBuggage.returnWeight > 0 && (
        <>
          <hr style={{ opacity: ".2" }} />
          <div className="d-f f-spb ptb-1 f-end">
            <div className="d-f f-col">
              <p className="primary">
                {data.roundData.voyageFrom} to {data.roundData.voyageTo}
              </p>
              {/* <p onClick={() => setShowOrderTray('two')} className="primary b-700 pointer" style={{ textDecoration: 'underline' }}>View Details</p> */}
              <p onClick={() => setShowOrderTray("two")} className="primary b-700 pointer" style={{ textDecoration: "underline" }}>
                Extra Luggage: {totalExcessBuggage.returnWeight}kg
              </p>
              <p>
                Total:{" "}
                <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
                  {totalExcessBuggage.returnAmount.toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}
                </span>
              </p>
            </div>
            <div className="txt-dec-u pointer p-r" onClick={() => handleRemove("returnWeight")} style={{ minWidth: "35px", minHeight: "35px" }}>
              {removeType == "returnWeight" && removeLoader ? <Loader color="#DF7435" /> : "Remove"}
            </div>
          </div>
        </>
      )}
      <div className="d-f f-spb f-end">
        <button onClick={() => setShow(true)} className="pointer btn">
          Change
        </button>
        <p className="txt-end">
          Total Fare:{" "}
          <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
            {(totalExcessBuggage.amount + totalExcessBuggage.returnAmount).toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}
          </span>
        </p>
      </div>
      {showOrderTray && (
        <div onClick={() => setShowOrderTray("")} className="overlay">
          <OrderTray chargeCurrency={chargeCurrency} totalExcessBuggage={totalExcessBuggage} completedpaxes={completedPaxes} showOrderTray={showOrderTray} />
        </div>
      )}
    </div>
  );
}

function OrderTray({ chargeCurrency, totalExcessBuggage, showOrderTray, completedpaxes }) {
  const type = showOrderTray == "one" ? "weight" : "returnWeight";
  const type2 = showOrderTray == "one" ? "amount" : "returnAmount";
  return (
    <div className="p-1 p-c" style={{ maxWidth: "722px", height: "fit-content" }}>
      <div className="p-8 bg-white br-r-12">
        <div className="d-f f-spb">
          <p>Pax</p>
          <p>Weight</p>
        </div>
        <hr />
        {completedpaxes.map((item, index) => (
          <div key={index}>
            <div className="d-f f-spb">
              <p style={{ fontFamily: "Arial, sans-serif" }}>{item.name | item.paxNumber.seqNo}</p>
              <p>{item[type]} Kg</p>
              {/* <p style={{ fontFamily: "Arial, sans-serif" }}>{item.amount.toLocaleString("en-GB", { style: "currency", currency: amountBreakDown[type].chargeCurrency })}</p> */}
            </div>
            <hr />
          </div>
        ))}
        {/* <div className="d-f f-spb mt-1" ><span>Total Taxes</span><span>{tax.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span></div>
                <div className="d-f f-spb mt-1"><span>Total Price</span><span>{showOrderTray == "one" ? mealInfo.oneWayAmount.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency }) : mealInfo.roundWayAmount.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span></div>
             */}
        <div className="d-f f-spb">
          {/* <p>Total Tax</p> */}
          {/* <p style={{ fontFamily: "Arial, sans-serif" }}>{(amountBreakDown[type].chargePriceWithTax - amountBreakDown[type].chargePrice).toLocaleString("en-GB", { style: "currency", currency: amountBreakDown[type].chargeCurrency })}</p> */}
        </div>
        <hr />
        <div className="d-f f-spb items-center">
          <p>Total Price</p>
          <p style={{ fontFamily: "Arial, sans-serif" }}>{totalExcessBuggage[type2].toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</p>
        </div>
        <hr />
        <hr />
      </div>
    </div>
  );
}
function Success({ setIsRemoved }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "450px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">You have successfully cancelled Extra Luggage!</p>
            <p>Please continue your booking!</p>
            <button className="pointer btn mt-1" onClick={() => setIsRemoved(false)} style={{ width: "120px" }}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
