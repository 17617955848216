import SearchVoyages from "../components/common/SearchVoyages";
import bgIMg from "../assets/images/kkm.jpg";
import appStore from "../assets/images/app_store.svg";
import playStore from "../assets/images/play_store.svg";
import { useMediaQuery } from "react-responsive";
import Discount from "../components/common/Discount";
import gifImg from "../assets/images/eta.png";
// import discountImg from "../assets/images/Sail Subham Discount Banner.png";
import { useEffect, useState } from "react";

export default function Home() {
  if (sessionStorage.getItem("clickOffer") == null)
    sessionStorage.setItem("clickOffer", 1);
  else if (sessionStorage.getItem("clickOffer" == 2))
    sessionStorage.setItem("clickOffer", 2);
  const [offer, setOffer] = useState(sessionStorage.getItem("clickOffer"));
  const isMob = useMediaQuery({ query: "(max-width:1024px)" });
  const style = {
    background: {
      backgroundImage: !isMob ? `url(${bgIMg})` : "",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      bottom: 0,
      right: 0,
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      overflowY: "auto",
    },
  };
  function handleSubmit(e) {
    e.preventDefault();
  }
  return (
    <div id="home-page">
      {/* {offer == 1 && (
        <Discount name="dis" img={discountImg} setOffer={setOffer} />
      )} */}

      {/* <div style={{ height: '100%', backgroundImage: `url(${url})` }}></div> */}
      <div
        id="home-page-wrapper"
        className="landing-f-col landing-flex-row m-a"
        style={style.background}
      >
        {!isMob && (
          <div className="b-700 sm-txt-c sm-mb-2">
            <h1 className="b-700 white" style={{ fontSize: "64px" }}>
              Cruise the <br /> Palk Strait <br /> Swiftly!
            </h1>
            <h2 className="b-500 white">
              Your gateway to coastal connections,
              <br />
              linking shores and souls.
            </h2>
            <div className="d-f c-gap-1 mt-1">
              {/* <img
                className="pointer"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.SAIL SUBHAM.ferry&pcampaignid=web_share",
                    "_blank"
                  )
                }
                src={playStore}
                alt=""
              />
              <img
                className="pointer"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/gb/app/sail-SAIL SUBHAM/id6480496722",
                    "_blank"
                  )
                }
                src={appStore}
                alt=""
              /> */}
            </div>
          </div>
        )}
        <SearchVoyages />
      </div>
    </div>
  );
}
