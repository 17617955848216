export default function PaxDetailCard({ data }) {
  return (
    <div className="d-f f-wrap mt-1 r-gap-1">
      <div className="w-50 sm-w-full sm-txt-a-l">
        <h1 className="b-500">Personal information</h1>
        <p className="gray">
          Date of Birth: <span className="black">{data.dob}</span>{" "}
        </p>
      </div>
      {/* <div className="w-50  sm-w-full lg-txt-a-r sm-txt-a-l">
                <h1 className="b-500">Travel information</h1>
                <p className="gray">Cabin: <span className="black"></span> </p>
                <p className="gray">Preferred Meal:<span className="black"></span></p>
            </div> */}
      <div className="w-50  sm-w-full sm-txt-a-l">
        <h1 className="b-500">Travel Document</h1>
        <p className="gray">
          Passport No: <span className="black">{data.passportNo}</span>
        </p>
        <p className="gray">
          Date of issue: <span className="black">{data.dateOfIssue}</span>
        </p>
        <p className="gray">
          Date of Expiray: <span className="black">{data.dateOfExpiry}</span>{" "}
        </p>
      </div>
      {/* <div className="w-50  sm-w-full lg-txt-a-r sm-txt-a-l">
                <h1 className="b-500">Ticket Fare:<span className="primary">{data.stdFareAmount}</span> </h1>
            </div> */}
    </div>
  );
}
