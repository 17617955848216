export default function TravelPolicies() {
  const style = {
    noteBoxe: {
      border: "1px solid",
      borderRadius: "5px",
      padding: "10px",
      margin: "2rem 0 0 0",
      borderColor: "#E76F51",
      backgroundColor: "rgba(231, 111, 81, 0.1)",
    },
  };
  return (
    <div
      className="br-r-1 bg-white m-a h-100"
      style={{
        overflow: "auto",
        maxWidth: "896px",
        padding: "2rem 1.5rem",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <h1 className="fs-20px b-500">Travel Policies</h1>

      <div>
        <p style={style.noteBoxe}>
          ⚠️ &nbsp; Customers and travel agents are advised to provide accurate
          passenger information at the time of booking. Any incorrect details,
          including spelling errors in names, passport information, or visa
          details, will incur a correction fee of{" "}
          <span className="b-700">₹1,000/- or LKR 3,500/- or USD 12</span> at
          the time of check-in.
        </p>
      </div>

      <ul>
        <br />
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Check-in and Arrival
        </li>
        <li className="mt-1-5">
          We recommend arriving at the port 3 hours before the scheduled
          departure time. The check-in counter will close 45 minutes before the
          scheduled departure time.
        </li>
      </ul>

      <ul>
        <br />
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>Refund Policy</li>
        <li className="mt-1-5">
          Cancellation 72 hours or more before departure: 80% of the ticket
          amount will be refunded.
        </li>
        <li className="mt-1-5">
          Cancellation between 24 hours to 72 hours before departure: 70% of
          the ticket amount will be refunded.
        </li>
        <li className="mt-1-5">
          Cancellation within 24 hours of departure: No refund will be
          provided.
        </li>
        <li className="mt-1-5">
          Voyage Cancellation Policy: In cases where the voyage is canceled by
          the management, customers will be entitled to either:
          <ul>
            <li>Full Refund: 100% of the ticket amount will be refunded.</li>
            <li>Rescheduling: The ticket can be rescheduled for a future date.</li>
          </ul>
        </li>
      </ul>

      <ul>
        <br />
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Reschedule Policy
        </li>
        <li className="mt-1-5">
          Rescheduling 72 hours before departure: A fee equivalent to 20% of
          the ticket amount will apply.
        </li>
        <li className="mt-1-5">
          Rescheduling between 24 to 72 hours before departure: A fee
          equivalent to 30% of the ticket amount will apply.
        </li>
        <li className="mt-1-5">
          Rescheduling within 24 hours of departure: Rescheduling is not
          permitted.
        </li>
      </ul>

      <ul>
        <br />
        <hr />
        <br />
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>Baggage Policy</li>
        <li className="mt-1-5">
          Hand Luggage: Passengers are allowed to carry one carry-on bag along
          with a personal item. The carry-on bag, including handles and wheels,
          must not exceed 22 x 14 x 9 inches (56 x 36 x 23 cm) and a maximum
          weight of 10 kg.
        </li>
        <li className="mt-1-5">
          Checked Baggage:
          <ul>
            <li>First checked bag: Fee of ₹1,200 for up to 20 kg.</li>
            <li>Second checked bag: Fee of ₹1,800 for up to 20 kg.</li>
            <li>Third checked bag: Fee of ₹3,000 for up to 10 kg.</li>
          </ul>
          A maximum of three bags is allowed per person. Each checked bag
          category allows only one bag. For example:
          <ul>
            <li>20 kg luggage: 1 bag allowed.</li>
            <li>40 kg luggage: 2 bags allowed.</li>
            <li>50 kg luggage: 3 bags allowed.</li>
          </ul>
          All checked baggage must be pre-booked online, and extra baggage
          cannot be added at check-in.
        </li>
      </ul>

      <ul>
        <br />
        <hr />
        <br />
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>Visa Requirements</li>
        <li className="mt-1-5">
          For Indian passport holders entering Sri Lanka:
          <ul>
            <li>
              A passport with at least 6 months (180 days) validity from the
              travel date is required.
            </li>
            <li>
              A return ticket or onward ticket (by Sea or Air) from Sri Lanka is
              required.
            </li>
          </ul>
        </li>
        <li className="mt-1-5">
          For Sri Lankan passport holders to enter India:
          <ul>
            <li>
              A valid passport with at least 6 months (180 days) validity from
              the entry date is required.
            </li>
            <li>
              A valid visa issued by an Indian High Commission or Embassy is
              mandatory. First-time entry with a new e-visa is not permitted
              through Nagapattinam port. However, subsequent entries using the
              same e-visa are allowed.
            </li>
          </ul>
        </li>
        <li className="mt-1-5">
          For all other international passport holders:
          <ul>
            <li>
              A passport with a minimum of 6 months (180 days) validity from the
              date of entry is required.
            </li>
            <li>
              If the country of passport issuance is exempt from visa
              requirements for India or Sri Lanka, no visa is needed. Otherwise,
              a valid visa is required.
            </li>
          </ul>
        </li>

        <li className="mt-1-5">
        Additional Notes For all Travelers:
          <ul>
            <li>
            All tourist and visitors must possess a valid return or onward journey ticket.

            </li>
            
          </ul>
        </li>

        

      </ul>
    </div>
  );
}
