import failImg from "../../assets/images/fail.png";
export default function Err({ data, click }) {
  return (
    <div className="p-c p-1" style={{ maxWidth: "500px", height: "fit-content" }}>
      <div className="p-8 br-r-12 bg-white">
        {/* <h1 className="red fs-20px b-500">Error . . .</h1> */}
        <div className="txt-a-c">
          <img src={failImg} alt="" width="80px" />
        </div>
        <p className="txt-a-c mt-1">{data.message}</p>
        <p className="gray">{data.alertMessage}</p>
        <div className="txt-a-c mt-1">
          <button className="btn pointer" onClick={click}>
            ok
          </button>
        </div>
      </div>
    </div>
  );
}
