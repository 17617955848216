import img1 from "../assets/images/aboutUs1.jpeg";
import img2 from "../assets/images/aboutUs2.jpeg";
export default function AboutUs() {
  return (
    <div className="br-r-1 bg-white m-a h-100" style={{ overflow: "auto", maxWidth: "896px", padding: "2rem 1.5rem", boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}>
      <h1 className="fs-20px b-500">Provide the best travel experience for you </h1>
      <p className="mt-1-5">We understand that every journey has unique needs. Therefore, we offer customized travel packages designed according to your preferences and budget.</p>
      
      <h1 className="b-700 mtb-1-5">Our Vision</h1>
      <p>Our vision is to be a premier travel agency that offers exceptional services and inspires our customers to explore the world.</p>
      <h1 className="b-700 mtb-1-5">Our Mission</h1>
      <p>Our mission is to deliver exceptional travel experiences by offering personalized services, expert guidance, and inspiration, ensuring every journey is memorable for our customers.</p>
     

      <p className="b-700 mt-1-5">SUBHAM FREIGHT CARRIERS INDIA PRIVATE LIMITED</p>
      <p>
        <span className="b-700">Reg No: </span>U60230TN2011PTC079906
      </p>
      <p>
        <span className="b-700">GST No: </span>33AAPCS5550D1ZD
      </p>
      <p className="b-700">Registered office : </p>
      <p>Subham Freight Carriers India Pvt Ltd</p>
      <p>SF,391/3BI,5A,6A,4A, Ezhichur,</p>
      <p>Shri Perambudur Taluk,</p>
      <p>Tamil Nadu, India - 631605, </p>
      
    </div>
  );
}
