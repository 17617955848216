import { useEffect, useState } from "react";
import { getBookings } from "../api/dashboard";
import { cancelReserving, cancelBooking } from "../api/api";
import { booking, continueBooking } from "../api/book";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Loader from "../components/icons/Loader";
import SearchFilter from "../components/common/SearchFilter";
import successImg from "../assets/images/success.png";
import failImg from "../assets/images/fail.png";
import view from "../assets/images/view-default.svg";
import viewHover from "../assets/images/view-hover.svg";
import cancelImg from "../assets/images/cancel-default.svg";
import cancelHover from "../assets/images/cancel-hover.svg";
import changeDate from "../assets/images/change-date-default.svg";
import changeDateHover from "../assets/images/change-date-hover.svg";
export default function Dashboard() {
  const isMob = useMediaQuery({ query: "(max-width:768px)" });
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [pendings, setPendings] = useState([]);
  const [creditLimit, setCreditLimit] = useState(0);
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(true);
  const [totalBookings, setTotalBookings] = useState(0);
  const [showCancel, setShowCancel] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [cancelBookingId, setcancelBookingId] = useState({ bookingId: "", paymentGateWay: "" });
  const isSlAGent = localStorage.getItem("role") == "3" && localStorage.getItem("chargeCurrency") == "LKR";
  const [msg, setMsg] = useState({
    status: "success",
    message: "",
  });
  const [fields, setFields] = useState({
    fromDate: "",
    toDate: "",
    fromPort: "",
    toPort: "",
    isSearch: false,
  });
  useEffect(() => {
    getBookings({
      fromDate: "",
      toDate: "",
      fromPort: "",
      toPort: "",
    }).then((data) => {
      if (data) {
        setCreditLimit(data.creditLimit);
        if (data.completedBooking.length > 0) {
          setBookings(data.completedBooking);
        }
        if (data.pendingBooking.length > 0) {
          setPendings(data.pendingBooking);
        }
        setTotalBookings(data.totalBookingCount);
      }
      setLoader(false);
    });
  }, []);
  function cancel(id) {
    setShowCancel(false);
    setLoader(true);
    cancelReserving({ bookingId: id }).then((data) => {
      if (data) {
        setMsg({
          status: "success",
          message: "Reservation Canceled !",
          alertMessage: "Your Reservation has been successfully canceled.",
        });
      } else {
        setMsg({
          message: "Cancellation Unsuccessful !",
          status: "We couldn't cancel your reservation at the moment. Please try again later.",
        });
      }
    });
  }
  function handleCancelBooking(params) {
    setShowRefund(false);
    setLoader(true);
    cancelBooking({ bookingId: params.bookingId, paymentGateWay: params.paymentGateWay }).then((data) => {
      if (data) {
        setLoader(false);
        setMsg({
          status: "success",
          message: "You have successfully cancelled your booking!",
          alertMessage: "Check your email for refund status. Thank you for choosing Sail Subham.",
        });
      } else {
        setMsg({
          message: "Something went wrong",
          alertMessage: "Please try again",
          status: "failed",
        });
      }
    });
  }
  function continueReservation(item) {
    setLoader(true);
    const reserveData = {
      bookingId: item.bookingId,
      type: item.type,
      round: item.type == "one" ? false : true,
      oneWayData: "",
      roundData: "",
      isReservation: true,
      noOfPax: item.noOfPax,
    };
    continueBooking({ bookingId: item.bookingId }).then((data) => {
      if (data) {
        reserveData.oneWayData = data.matchingVoyage;
        reserveData.roundData = data.returnMatchingVoyage;
        reserveData.payableAmount = data.payableAmount;
        // reserveData.travelDate = data.travelDate;
        setLoader(false);
        localStorage.setItem("bookingId", JSON.stringify(item.bookingId));
        navigate("/voyages/fare-summary", { state: { book: reserveData, travelDate: data.travelDate } });
      }
    });
  }
  const style = {
    box: {
      width: "320px",
      height: "110px",
      borderRadius: "1rem",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      padding: "1rem 2rem",
      justifyContent: "end",
    },
  };
  if (!localStorage.getItem("token")) return window.location.assign("/");
  return (
    <div className="m-a fade sm-p-1" style={{ maxWidth: "1088px " }}>
      {localStorage.getItem("role") != "1" && (
        <div id="dashboard-box-wrapper" className="d-f f-spb mb-2 f-wrap">
          {localStorage.getItem("chargeCurrency") == "LKR" && (
            <div id="credit-available" className="d-f f-col" style={style.box}>
              <p className="fs-1-5 b-500">{creditLimit.toLocaleString("ta-LK")}</p>
              <div className="d-f f-spb" style={{ alignItems: "end" }}>
                <span>Credits Available</span>
                <div className="d-f f-j-c f-a-c" style={{ width: "48px", height: "48px", borderRadius: "50%", color: " #006CCF", fontSize: "1.5rem", background: "#CCE9FF" }}>
                  Rs
                </div>
              </div>
            </div>
          )}
          <div className="bookings d-f f-col" style={style.box}>
            <p className="fs-1-5 b-500 sm-txt-c" style={{ color: "#11B800" }}>
              {totalBookings}
            </p>
            <div className="sm-f-j-c d-f f-spb" style={{ alignItems: "end" }}>
              <span className="sm-fs-14px txt-a-c">Total Bookings</span>
              <div className="f-a-c f-j-c" style={{ display: `${isMob ? "none" : "flex"}`, width: "48px", height: "48px", borderRadius: "50%", color: " #006CCF", fontSize: "2rem", background: "#CDFFC8" }}>
                <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79083 0 4 0H20C22.2092 0 24 1.79086 24 4C21.7908 4 20 5.79086 20 8C20 10.2091 21.7908 12 24 12C24 14.2091 22.2092 16 20 16H4C1.79083 16 0 14.2091 0 12V11.874C1.72522 11.43 3 9.86383 3 8C3 6.13617 1.72522 4.57004 0 4.12601V4Z" fill="#11B800" />
                </svg>
              </div>
            </div>
          </div>
          <div className="bookings d-f f-col" style={style.box}>
            <p className="sm-txt-c fs-1-5 b-500" style={{ color: "#FF7A00" }}>
              {pendings.length}
            </p>
            <div className="sm-f-j-c d-f f-spb" style={{ alignItems: "end" }}>
              <span className="sm-fs-14px txt-a-c">Total Reservations</span>
              {!isMob && (
                <div className="d-f f-j-c" style={{ width: "48px", height: "48px", borderRadius: "50%", color: " #FF7A00", fontSize: "2rem", background: "#FFE9D4" }}>
                  ?
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="d-f f-spb sm-f-col sm-r-gap-1" style={{ alignItems: "end" }}>
        {/* <h1 className="fs-20px b-500">My Booking History</h1> */}
        <div className="d-f c-gap-1 f-wrap r-gap-1 sm-f-j-c sm-m-a">
          {/* {localStorage.getItem('role') == "1" ? <p>Bookings</p> : <><input id="bookings" type="radio" name="filter" style={{ width: '24px', height: "24px" }} defaultChecked onChange={() => setShow(!show)} /> &nbsp;<label className="b-500 mr-2" htmlFor="bookings">Bookings</label></>}
                    {localStorage.getItem('role') != "1" && <><input id="pendings" type="radio" name="filter" style={{ width: '24px', height: "24px" }} onChange={() => setShow(!show)} />&nbsp;<label className="b-500" htmlFor="pendings">Reservations</label></>} */}
          {localStorage.getItem("role") != "1" && (
            <>
              <button className={`br-n b-500 pointer ${show ? "bg-primary white" : ""}`} style={{ height: "62px", width: "194px", borderRadius: "4px" }} onClick={() => setShow(true)}>
                Bookings
              </button>
              <button className={`br-n b-500 pointer ${!show ? "bg-primary white" : ""}`} style={{ height: "62px", width: "194px", borderRadius: "4px" }} onClick={() => setShow(false)}>
                Reservations
              </button>
            </>
          )}
        </div>
        {!isMob && (
          <button className="btn b-500 pointer" onClick={() => navigate("/home")}>
            New Booking
          </button>
        )}
      </div>
      {show && <SearchFilter fields={fields} setFields={setFields} setBookings={setBookings} setLoader={setLoader} />}
      <h1 className="fs-20px b-500 mt-1">My bookings</h1>
      <div className="p-8 mt-1 sm-p-0" style={{ display: loader ? "none" : "block", boxShadow: isMob ? "" : " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)", borderRadius: "1rem" }}>
        {!isMob && (
          <table id="booking-table" className="w-full">
            {show && bookings.length > 0 && (
              <thead className="primary">
                <tr>
                  <th>Booked On</th>
                  <th className="remove">Travel Type</th>
                  {show && <th className="remove">Booking No</th>}
                  <th>Departure On</th>
                  {show && <th className="remove">Return On</th>}
                  <th className="remove">Pax(s)</th>
                  <th className="remove">Class</th>
                  <th colSpan="3">Action</th>
                </tr>
              </thead>
            )}
            {!show && pendings.length > 0 && (
              <thead className="primary">
                <tr>
                  <th>Reserved On</th>
                  <th className="remove">Travel Type</th>
                  {show && <th className="remove">Booking No</th>}
                  <th>Departure On</th>
                  {show && <th className="remove">Return On</th>}
                  <th className="remove">Pax(s)</th>
                  <th className="remove">Class</th>
                  <th colSpan="3">Action</th>
                </tr>
              </thead>
            )}
            <tbody className="mt-2">
              {show &&
                bookings.map((item, index) => (
                  <tr key={index}>
                    <td>{item.confirmedDate.split("/").join(".")}</td>
                    <td className="remove">
                      <span className={`remove travel-type ${item.type == "one" ? "one-way" : "round"}`}>{item.type == "one" ? "One Way" : "Round"}</span>
                    </td>
                    {show && <td className="remove">{item.bookingId}</td>}
                    <td>{item.depatureDate.split("/").join(".")}</td>
                    <td className="remove">{item.type == "two" ? item.returnDate.split("/").join(".") : "-"}</td>
                    <td className="remove">{item.paxCount > 0 && item.paxCount < 10 ? `0${item.paxCount}` : item.paxCount}</td>
                    <td className="remove">
                      <span className={`travel-type ${item.classType.toLowerCase()}`}>{item.classType}</span>
                    </td>
                    <td>
                      <img src={view} alt="" className="pointer" onClick={() => navigate("detail", { state: { bookingId: item.bookingId } })} onMouseLeave={(e) => (e.target.src = view)} onMouseOver={(e) => (e.target.src = viewHover)} />
                      {/* <button onClick={() => navigate('detail', { state: { bookingId: item.bookingId } })} className="btn pointer" style={{ width: '90px', height: "34px" }}>View</button>*/}
                    </td>
                    {/* <td className="tooltip">
                      <img
                        src={changeDate}
                        alt=""
                        style={{ opacity: item.changeStatusOneway || item.changeStatusReturnway ? 1 : 0.5 }}
                        className="pointer"
                        onClick={() => {
                          if (item.changeStatusOneway || item.changeStatusReturnway) {
                            localStorage.setItem("changeBookingId", JSON.stringify(item.bookingId));
                            item.travelType = item.type;
                            navigate("/change-date", { state: item });
                          }
                        }}
                        onMouseLeave={(e) => (e.target.src = changeDate)}
                        onMouseOver={(e) => (e.target.src = changeDateHover)}
                      />
                      <span className="tooltiptext">Change Date</span>
                    </td> */}
                    {/* <td>
                      <img
                        src={cancelImg}
                        alt=""
                        className="pointer"
                        style={{ opacity: item.cancelStatus ? 1 : 0.4 }}
                        onClick={() => {
                          if (item.cancelStatus) {
                            setcancelBookingId({ bookingId: item.bookingId, paymentGateWay: item.paymentGateWay });
                            setShowRefund(true);
                          }
                        }}
                        onMouseLeave={(e) => (e.target.src = cancelImg)}
                        onMouseOver={(e) => (e.target.src = cancelHover)}
                      />
                    </td> */}
                  </tr>
                ))}
              {show && bookings.length == 0 && (
                <>
                  <tr className="d-f f-col" style={{ height: "auto" }}>
                    <td>
                      <img src={failImg} alt="" width="150px" />
                    </td>
                    {fields.isSearch ? (
                      <>
                        <td className="primary fs-1-5 mt-1">There are no bookings available on the selected date.</td>
                        <td className="mt-1">Please check the date and search again to find your booking list.</td>
                      </>
                    ) : (
                      <>
                        <td className="primary fs-1-5 mt-1">No Upcoming Bookings Available</td>
                        <td className="mt-1">If you don't have any upcoming bookings. You can also check your previous bookings by searching for them in the search box above.</td>
                      </>
                    )}
                  </tr>
                </>
              )}
              {!show &&
                pendings.length > 0 &&
                pendings.map((item, index) => (
                  <tr key={index}>
                    <td>{item.searchedDate.split("/").join(".")}</td>
                    <td className="remove ">
                      <span className={`travel-type ${item.type == "one" ? "one-way" : "round"}`}>{item.type == "one" ? "One Way" : "Round"}</span>
                    </td>
                    {show && <td className="remove"></td>}
                    <td>{item.depatureDate.split("/").join(".")}</td>
                    {/* <td className="remove">{item.type == "two" ? item.returnDate.split('/').join('.') : '-'}</td> */}
                    <td className="remove">{item.paxCount > 0 && item.paxCount < 10 ? `0${item.paxCount}` : item.paxCount}</td>
                    <td className="remove">
                      <span className={`travel-type ${item.classType.toLowerCase()}`}>{item.classType}</span>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setcancelBookingId({ bookingId: item.bookingId, paymentGateWay: "" });
                          setShowCancel(true);
                        }}
                        className="br-btn primary pointer"
                        style={{ width: "90px", height: "34px" }}
                      >
                        Cancel
                      </button>
                    </td>
                    <td>
                      {show ? (
                        "View details"
                      ) : (
                        <button onClick={() => continueReservation(item)} className="btn pointer plr-1" style={{ width: "90px", height: "34px" }}>
                          Book
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              {!show && pendings.length == 0 && (
                <>
                  <tr className="d-f f-col" style={{ height: "auto" }}>
                    <td>
                      <img src={failImg} alt="" width="150px" />
                    </td>
                    <td className="primary fs-1-5 mt-1">No Current Reservations Found</td>
                    <td className="mt-1">you have no current reservations, or your reservation was automatically canceled after 3 hours.</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        )}
        {isMob && (
          <div className="d-f f-col r-gap-1">
            {pendings.length > 0 && (
              <div className="d-f primary b-500 fs-14px f-spb">
                <p>Booked On</p>
                <p>Travel Type</p>
                <p>Departure On</p>
              </div>
            )}
            {show &&
              bookings.length > 0 &&
              bookings.map((item, index) => (
                <div className="d-f f-col" key={index}>
                  <div className="d-f f-spb">
                    <p>{item.confirmedDate.split("/").join(".")}</p>
                    <p className="remove">
                      <span className={`remove travel-type ${item.type == "one" ? "one-way" : "round"}`}>{item.type == "one" ? "One Way" : "Round"}</span>
                    </p>
                    <p>{item.depatureDate.split("/").join(".")}</p>
                  </div>
                  <div className="d-f f-j-c mt-2 pb-1 f-a-c" style={{ columnGap: ".5rem", borderBottom: "1px solid var(--bgGray)" }}>
                    {/* <button
                      disabled={!(item.changeStatusOneway || item.changeStatusReturnway)}
                      onClick={() => {
                        if (item.changeStatusOneway || item.changeStatusReturnway) {
                          localStorage.setItem("changeBookingId", JSON.stringify(item.bookingId));
                          item.travelType = item.type;
                          navigate("/change-date", { state: item });
                        }
                      }}
                      className="br-btn primary pointer"
                      style={{ width: "max-content", height: "34px", opacity: item.changeStatusOneway || item.changeStatusReturnway ? 1 : 0.5 }}
                    >
                      Change Date
                    </button> */}
                    {/* <div className="d-f c-gap-1"> */}
                    <button onClick={() => navigate("detail", { state: { bookingId: item.bookingId } })} className="btn pointer" style={{ width: "90px", height: "34px" }}>
                      View
                    </button>
                    {/* <button
                      disabled={!item.cancelStatus}
                      onClick={() => {
                        if (item.cancelStatus) setcancelBookingId({ bookingId: item.bookingId, paymentGateWay: item.paymentGateWay });
                        setShowRefund(true);
                      }}
                      className="br-btn primary pointer"
                      style={{ width: "90px", height: "34px", opacity: item.cancelStatus ? 1 : 0.4 }}
                    >
                      Cancel
                    </button> */}
                    {/* </div> */}
                  </div>
                </div>
              ))}
            {show && bookings.length == 0 && (
              <div className="d-f f-col f-a-c mt-2" style={{ height: "auto" }}>
                <img src={failImg} alt="" width="100px" />
                <p className="mt-8px primary fs-20px txt-a-c">No Upcoming Bookings Available</p>
                <p className="txt-a-c mt-8px">If you don't have any upcoming bookings. You can also check your previous bookings by searching for them in the search box above.</p>
              </div>
            )}
            {!show &&
              pendings.map((item, index) => (
                <div className="d-f f-col" key={index}>
                  <div className="d-f f-spb">
                    <p>{item.searchedDate.split("/").join(".")}</p>
                    <p className="remove ">
                      <span className={`travel-type ${item.type == "one" ? "one-way" : "round"}`}>{item.type == "one" ? "One Way" : "Round"}</span>
                    </p>
                    <p>{item.depatureDate.split("/").join(".")}</p>
                  </div>

                  <div className="d-f f-j-c c-gap-1 mt-2 pb-1">
                    <button onClick={() => continueReservation(item)} className="btn pointer" style={{ width: "90px", height: "34px" }}>
                      Book
                    </button>
                    <button
                      onClick={() => {
                        setcancelBookingId({ bookingId: item.bookingId, paymentGateWay: "" });
                        setShowCancel(true);
                      }}
                      className="br-btn primary pointer"
                      style={{ width: "90px", height: "34px" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ))}
            {!show && pendings.length == 0 && (
              <div className="d-f f-col f-a-c" style={{ height: "auto" }}>
                <img src={failImg} alt="" width="80px" />
                <p className="mt-8px primary fs-20px txt-a-c">No Current Reservations Found</p>
                <p className="txt-a-c mt-8px">you have no current reservations, or your reservation was automatically canceled after 3 hours.</p>
              </div>
            )}
          </div>
        )}
      </div>
      {loader && (
        <div className="overlay">
          <Loader color="white" />
        </div>
      )}
      {msg.message && <Success data={msg} setMsg={setMsg} />}
      {showCancel && <CancelConfirm setShowCancel={setShowCancel} cancel={() => cancel(cancelBookingId.bookingId)} />}
      {showRefund && <RefundConfirm setShowRefund={setShowRefund} cancel={() => handleCancelBooking(cancelBookingId)} />}
    </div>
  );
}

function CancelConfirm({ setShowCancel, cancel }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "400px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <h1 className="primary b-500 txt-a-c">Are You sure !</h1>
          <p className="txt-a-c gray">Booking Cancellation cannot be undone.</p>

          <div className="d-f c-gap-8 f-j-c mt-1">
            <button style={{ width: "100px", height: "35px" }} onClick={() => setShowCancel(false)} className="br-btn pointer">
              No
            </button>
            <button onClick={cancel} className="btn pointer" style={{ width: "100px", height: "35px" }}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
    // <div className='overlay'>
    //     <div className="p-c p-8 w-full h-100 d-f f-j-c f-a-c">
    //         <div className="fade p-1-5 p-r d-f br-r-1 w-full h-100" style={{ maxWidth: "416px", maxHeight: "438px", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
    //             <div className="d-f f-col f-spb">
    //                 <h1 className="primary b-500 txt-a-c">Are You sure!</h1>
    //                 <p className="txt-a-c gray">once you cancel the Booking, you can't get again</p>
    //                 <div className="d-f f-col r-gap-8 f-a-c">
    //                     <button onClick={() => window.location.assign('/account')} className="btn pointer">No</button>
    //                     <button className="br-btn pointer" style={{ width: "171px" }} onClick={() => console.log('hi')} >Yes</button>
    //                 </div>
    //             </div>
    //         </div>
    //     </div >
    // </div>
  );
}
function RefundConfirm({ setShowRefund, cancel }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "400px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <h1 className="primary b-500 txt-a-c">Are You sure!</h1>
          <p className="txt-a-c gray">By confirming, you will permanently cancel your booking. This action cannot be reversed.</p>

          <div className="d-f c-gap-8 f-j-c mt-1">
            <button style={{ width: "100px", height: "35px" }} onClick={() => setShowRefund(false)} className="br-btn pointer">
              No
            </button>
            <button onClick={cancel} className="btn pointer" style={{ width: "100px", height: "35px" }}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function Success({ data, setMsg }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={data.status == "success" ? successImg : failImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">{data.message}</p>
            <p>{data.alertMessage}</p>
            <button className="pointer msg-btn mt-1" onClick={() => window.location.assign("/dashboard")}>
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
