export default function ViewDetails({ maxLuggage }) {
  return (
    <div className="fade p-1 d-f sm-f-col c-gap-8 sm-r-gap-1-5" style={{ marginTop: "3rem" }}>
      <div>
        <h1 className="b-500 m-b-1">Luggage Information</h1>
        {/* <p className="gray">Chekc-In Luggage:50Kg</p>
                <p className="gray mt-8px">Hand Luggage:5kg</p> */}
        <p className="gray">Extra Luggage: Max {maxLuggage}kg</p>
      </div>
      <div>
        <h1 className="b-500 m-b-1">Seat Informtion</h1>
        <p className="gray">Preferred Seat Selection Available</p>
      </div>
      <div>
        <h1 className="b-500 m-b-1">Meal Information</h1>
        <p className="gray">Preferred Meal Selection Available</p>
      </div>
    </div>
  );
}
