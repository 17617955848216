import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPax, storePax } from "../../api/api";
import upImg from "../../assets/images/up.png";
import downImg from "../../assets/images/down.svg";
import successImg from "../../assets/images/success.png";
import failImg from "../../assets/images/fail.png";
import editImg from "../../assets/images/edit.png";
import Loader from "../icons/Loader";
import { getCurrency } from "../../api/utils";
import { ProcessContext } from "../ProcessContext";
import { countries } from "../../data/country";
import { nationalityList } from "../../data/nationality";
const paxType = {
  1: "Adult",
  2: "Child",
  3: "Infant",
};
export default function PassengerDts({ bookingId, setPaxSuccess, type, toPort }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const value = useContext(ProcessContext);
  const [completedpaxes, setCompletedPaxes] = useState([]);
  const [incompletedpaxes, setIncompletedPaxes] = useState([]);
  const [pax, setPax] = useState({});
  const [currentPax, setCurrentPax] = useState("");
  const [dropDownPax, setDropDownPax] = useState(false);
  const [loader, setLoader] = useState(true);
  const [msg, setMsg] = useState({ message: "", alertMessage: "" });
  const [success, setSuccess] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    dialCode: "",
    flag: "",
  });
  const [timeOut, setTimeOut] = useState(false);
  function getAddedPax() {
    setMsg("");
    setLoader(true);
    getPax({ bookingId: bookingId }).then((data) => {
      if (data) {
        setIncompletedPaxes(data.inCompletedPax);
        setCompletedPaxes(data.completedPax);
        if (data.inCompletedPax.length == 0) {
          setSuccess(true);
          setPaxSuccess(true);
        }
      }
      setLoader(false);
    });
  }
  useEffect(() => {
    // value.setShowSearchBtn(false);
    // value.setDate(state.date)
    getPax({ bookingId: bookingId }).then((data) => {
      if (data) {
        setIncompletedPaxes(data.inCompletedPax);
        setCompletedPaxes(data.completedPax);
        if (data.inCompletedPax.length == 0) {
          setSuccess(true);
          setPaxSuccess(true);
        }
        if (data.inCompletedPax.length == 0 && data.completedPax.length == 0) setTimeOut(true);
      }
      setLoader(false);
    });
    getCurrency().then((data) => {
      if (data) {
        const getCountry = countries.find((item) => data.country_calling_code == item.dial_code);
        setCurrentCountry({
          dialCode: getCountry.dial_code,
          flag: getCountry.flag,
        });
        // setDialCode(data.country_calling_code)
      } else {
        setCurrentCountry({
          dialCode: "+94",
          flag: "🇱🇰",
        });
      }
    });
  }, []);
  return (
    <div id="passenger-dts" className="m-a">
      {incompletedpaxes.length > 0 && (
        <>
          <h1 className="fs-20px b-500 mb-1-5">Uncompleted Passenger Details</h1>
          <p>Complete your booking by providing the necessary passenger details.</p>
        </>
      )}

      <div className="mt-2 d-f f-wrap r-gap-8" style={{ columnGap: "60px" }}>
        {incompletedpaxes.length > 0 && incompletedpaxes.map((item, index) => <Passenger key={index} data={item} onClick={() => setCurrentPax(item)} />)}
      </div>
      {completedpaxes.length > 0 && (
        <>
          <h1 className="fs-20px b-500 mt-1">Completed Passenger(s)</h1>
          <p>Passengers’ details are shown below. Please check the details before confirm the ticket(s).</p>
        </>
      )}

      <div className="mt-1 d-f f-wrap r-gap-1" style={{ columnGap: "3.5rem", alignItems: "flex-start" }}>
        {completedpaxes.length > 0 && completedpaxes.map((item, index) => <Passenger key={index} data={item} onClick={() => setCurrentPax(item)} />)}
      </div>

      {currentPax && <Detail type={type} toPort={toPort} currentCountry={currentCountry} setCurrentCountry={setCurrentCountry} getAddedPax={getAddedPax} setLoader={setLoader} setMsg={setMsg} bookingId={bookingId} setCurrentPax={setCurrentPax} currentPax={currentPax} />}
      {msg.message && <Success data={msg} getAddedPax={getAddedPax} />}
      {loader && (
        <div className="overlay">
          <Loader color="white" />
        </div>
      )}
      {timeOut && (
        <div className="overlay d-f f-j-c f-a-c p-1">
          <div className="bg-white br-r-12 p-1 d-f f-j-c f-col f-a-c txt-a-c" style={{ width: "max-content" }}>
            <div>
              <img src={failImg} alt="" width="80px" />
            </div>
            <h1 className="primary b-500 mt-1">Oh! You have exceeded the given time of 1hr to complete!</h1>
            <p className="gray" style={{ marginTop: ".5rem" }}>
              Please try again the booking with your planned dates!
            </p>
            <button className="btn mt-1 pointer" onClick={() => navigate("/home")} style={{ width: "90px", height: "40px" }}>
              ok
            </button>
          </div>
        </div>
      )}
      {/* {success && <div className="m-a txt-a-c" style={{ height: 'fit-content', width: 'fit-content' }}>
                <img src={successImg} alt="" />
                <h1 className="b-500 mtb-1">Your Ticket(s) has been booked successfully!</h1>
                <p className="gray">Check your mail box for the ticket(s) or Download here</p>
                <div className="txt-a-c mt-1">
                    <button onClick={() => navigate('/dashboard/detail', { state: { bookingId: state.bookingId } })} className="pointer btn mr-1" style={{ width: '265px' }}>Download Ticket(s)</button>
                    <button onClick={() => window.location.assign('/dashboard')} className="pointer br-btn primary" style={{ width: '265px' }}>Back to Dashboard</button>
                </div>
            </div>} */}
    </div>
  );
}

function Passenger({ data, onClick }) {
  const [show, setShow] = useState(false);
  return (
    <div className="br-r-1 p-1 sm-w-full" style={{ width: "320px", paddingRight: 0, minHeight: "84px", boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)" }}>
      <div className="d-f f-spb f-a-c">
        <div>
          <p className="b-500">{data.otherName ? data.otherName + " " + data.sureName : "Passenger " + data.seqNo}</p>
          <span className="fs-14px">{paxType[data.passengerType]}</span>
        </div>
        {/* <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1L5 5L1 1" stroke="#2500BA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5L5 1L9 5" stroke="#2500BA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg> */}
        {
          data.passportNo ? (
            <>
              {show ? (
                <div onClick={() => setShow(false)} className="pr-4 pointer">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5L5 1L9 5" stroke="var(--primaryColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              ) : (
                <div onClick={() => setShow(true)} className="pr-4 pointer">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1L5 5L1 1" stroke="var(--primaryColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              )}
            </>
          ) : (
            <svg onClick={onClick} className="pointer mr-1" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="24" fill="#D9D9D9" />
              <rect x="22" y="11" width="4" height="27" fill="var(--primaryColor)" />
              <rect x="10.5" y="26.5" width="4" height="27" transform="rotate(-90 10.5 26.5)" fill="var(--primaryColor)" />
            </svg>
          )
          // : <button onClick={onClick} className="btn pointer mr-1" style={{ width: '60px', height: 'fit-content' }}>Add</button>
        }
        {/* <button onClick={onClick} className="btn pointer" style={{ width: '60px', height: 'fit-content' }}>Add</button> */}
      </div>
      {show && (
        <div className="d-f f-spb f-end">
          <ShowDetail data={data} />
          <div className="pointer" onClick={onClick}>
            <img className="mr-1" src={editImg} alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

function Detail({ type, toPort, currentCountry, setCurrentCountry, setMsg, setLoader, currentPax, setCurrentPax, bookingId, getAddedPax }) {
  const contextValue = useContext(ProcessContext);
  const minDate = new Date(contextValue.travelDate).toLocaleDateString("en-GB").split("/").reverse().join("-");
  const [paxDetail, setPaxDetail] = useState({
    otherName: currentPax.otherName ? currentPax.otherName : "",
    sureName: currentPax.sureName ? currentPax.sureName : "",
    email: currentPax.email ? currentPax.email : "",
    passportNo: currentPax.passportNo ? currentPax.passportNo : "",
    dob: currentPax.dob ? currentPax.dob : "",
    nationality: currentPax.nationality ? currentPax.nationality : "",
    countryCode: currentPax.countryCode ? currentPax.countryCode : currentCountry.dialCode,
    contactNo: currentPax.contactNo ? currentPax.contactNo : "",
    visaNumber: currentPax.visaNumber ? currentPax.visaNumber : "",
    dateOfIssue: currentPax.dateOfIssue ? currentPax.dateOfIssue : "",
    dateOfExpiry: currentPax.dateOfExpiry ? currentPax.dateOfExpiry : "",
    visaExpiryDate: currentPax.visaExpiryDate ? currentPax.visaExpiryDate : "",
  });

  const [showCountry, setShowCountry] = useState(false);
  const [showNationality, setShowNationlity] = useState(false);
  const [valid, setValid] = useState({
    otherName: currentPax.otherName ? true : false,
    sureName: currentPax.sureName ? true : false,
    dob: currentPax.dob ? true : false,
    dateOfExpiry: currentPax.dateOfExpiry ? true : false,
    dateOfIssue: currentPax.dateOfIssue ? true : false,
    passportNo: currentPax.passportNo ? true : false,
    contactNo: currentPax.contactNo ? true : true,
    nationality: currentPax.nationality ? true : false,
    // visaExpiryDate: "",
    // dateOfExpiry: ""
  });
  const [validErr, setValidErr] = useState({
    otherName: false,
    sureName: false,
    dob: false,
    dateOfExpiry: false,
    dateOfIssue: false,
    passportNo: false,
    contactNo: false,
    // visaExpiryDate: false,
    // dateOfExpiry: false
  });
  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    if (currentPax.otherName) checkValid();
  }, []);
  function checkValid() {
    let check = true;
    for (let i in valid) {
      check &&= valid[i];
    }
    if (check) setSubmit(true);
    else setSubmit(false);
  }
  function handleSubmit(e) {
    e.preventDefault();
    setLoader(true);
    paxDetail.countryCode = String(paxDetail.countryCode);
    const postData = { ...currentPax, ...paxDetail, bookingId: bookingId };
    storePax(postData).then((data) => {
      setLoader(false);
      setMsg({ message: data.message, alertMessage: data.alertMessage });
      setCurrentPax("");
      setTimeout(() => getAddedPax(), 3000);
    });
  }
  function findPaxType(type) {
    switch (type) {
      case 1:
        return "adult";
      case 2:
        return "child";
      case 3:
        return "infant";
    }
  }
  function dateCheck180(date, value) {
    const add180Days = new Date(date);
    add180Days.setDate(add180Days.getDate() + 180);
    const checkDate = add180Days.setHours(0, 0, 0, 0) > new Date(value).setHours(0, 0, 0, 0);
    if (checkDate && value) {
      setValidErr({ ...validErr, dateOfExpiry: "Must be valid for next 6 months" });
      valid.dateOfExpiry = false;
    } else {
      valid.dateOfExpiry = value ? true : false;
      setValidErr({ ...validErr, dateOfExpiry: "" });
    }
  }
  function passportExpirayValid(value, nationality) {
    if (type == "one") {
      if ((nationality == "sri lankan" && toPort == "KKS") || (type == "one" && nationality == "indian" && toPort == "NGP")) {
        valid.dateOfExpiry = value ? true : false;
        setValidErr({ ...validErr, dateOfExpiry: "" });
      } else {
        dateCheck180(contextValue.travelDate, value);
      }
    } else {
      if ((type == "two" && nationality == "sri lankan" && toPort == "NGP") || (type == "two" && nationality == "indian" && toPort == "KKS")) {
        // valid.dateOfExpiry = value ? true : false;
        // setValidErr({ ...validErr, dateOfExpiry: "" })
        dateCheck180(contextValue.travelDate, value);
      } else {
        dateCheck180(contextValue.returnTravelDate, value);
      }
    }
  }
  function handleChange(e) {
    let value = e.target.value;
    const name = e.target.name;
    switch (name) {
      case "otherName":
        if (value.length == 0) {
          setValidErr({ ...validErr, otherName: "can't be empty" });
          valid.otherName = false;
        } else valid.otherName = true;
        break;
      case "sureName":
        if (value.length == 0) {
          setValidErr({ ...validErr, sureName: "can't be empty" });
          valid.sureName = false;
        } else valid.sureName = true;
        break;
      case "dob":
        let date = Date.now(contextValue.travelDate) - new Date(value).getTime();
        // function calculateAge(dateOfBirth) {
        //     // Get today's date
        //     var today = new Date();
        //     var diffInMilliSeconds = today.getTime() - dateOfBirth.getTime();

        //     // 1 year = 365.25 days
        //     var diffInYears = diffInMilliSeconds / 1000 / 60 / 60 / 24 / 365.25;
        //     return Math.abs(Math.round(diffInYears));
        // }
        date = Math.ceil(date / (1000 * 60 * 60 * 24 * 365));
        // date = new Date(date).getUTCFullYear() - 1970;
        if (date < 0) {
          setValidErr({ ...validErr, dob: "Invalid Date" });
          valid.dob = false;
          break;
        }
        if (date > 12 && currentPax.passengerType == 1) valid.dob = true;
        else if (date <= 12 && date > 2 && currentPax.passengerType == 2) valid.dob = true;
        else if (date <= 2 && currentPax.passengerType == 3) valid.dob = true;
        else valid.dob = false;
        if (!valid.dob) setValidErr({ ...validErr, dob: `Given DoB is not appropriate with the age of ${findPaxType(currentPax.passengerType)}` });
        break;
      case "dateOfIssue":
        if (new Date(value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) valid.dateOfIssue = true;
        else {
          setValidErr({ ...validErr, dateOfIssue: "Invalid Date" });
          valid.dateOfIssue = false;
        }
        break;
      case "passportNo":
        if (value.length == 0) {
          setValidErr({ ...validErr, passportNo: "can't be empty" });
          valid.passportNo = false;
        } else valid.passportNo = true;
        break;
      case "contactNo":
        if (isNaN(value)) {
          setValidErr({ ...validErr, contactNo: "Please enter number" });
          valid.contactNo = false;
        } else if (value.length > 10) {
          setValidErr({ ...validErr, contactNo: "Numbers too long" });
          valid.contactNo = false;
        } else valid.contactNo = true;
        break;
      case "dateOfExpiry":
        passportExpirayValid(value, paxDetail.nationality);
    }
    if (e.target.type == "date") {
      const date = new Date(value).toLocaleDateString("en-GB");
      value = date;
    }
    if (valid[name]) setValidErr({ ...validErr, [name]: "" });
    setPaxDetail({ ...paxDetail, [e.target.name]: value });
    checkValid();
  }
  const paxAgeType = {
    1: "above 12 years",
    2: "2 -12 years",
    3: "less than or equal to 2 years",
  };
  const getCountry = countries.find((item) => Number(item.dial_code) == paxDetail.countryCode);
  let flagAndCode = `${getCountry?.flag} ${getCountry.dial_code}`;
  return (
    <div className="overlay">
      <div id="pax-details" className=" br-r-12 p-c bg-white md-br-r-n sm-br-r-n" style={{ height: "fit-content", maxWidth: "1000px" }}>
        <div className="w-full m-a p-8 sm-p-1">
          <h1 className="fs-20px b-500 mb-1 sm-mt-1">
            Passenger Details ({paxType[currentPax.type]}&nbsp;{currentPax.seqNo}) - {paxType[currentPax.passengerType]}
          </h1>
          {/* <p className="txt-a-c mb-1-5">
                        When traveling internationally, use your passport name. Include the middle name after the First/Given Name, <br />
                        separated by a blank space. For a single name like 'Gayatri,' enter 'Last Name' as Gayatri and repeat it in the <br />
                        First/Given Name as Gayatri/Gayatri.
                    </p> */}
          <hr />
          <form onSubmit={handleSubmit} className="mt-2">
            <h1 className="fs-20px b-500">Personal Information</h1>
            <div className="d-f f-wrap f-spb r-gap-1 mt-1 mb-2">
              {/* <div className="sm-w-full">
                            <label className="b-500 star" htmlFor="">Title</label><br />
                            <input className="inp-n-w" type="text" />
                        </div> */}
              <div className="md-w-full">
                <label className="b-500 star" htmlFor="">
                  First Name / Other Name
                </label>
                <br />
                <input onChange={handleChange} name="otherName" placeholder="First Name / Other Name" className="inp-n-w pl-1" type="text" value={paxDetail.otherName} />
                <br />
                {validErr.otherName && <span className="red">{validErr.otherName}</span>}
              </div>
              <div className="md-w-full">
                <label className="b-500 star" htmlFor="">
                  Last Name / Sure Name
                </label>
                <br />
                <input onChange={handleChange} name="sureName" placeholder="Last Name / Sure Name" className="inp-n-w pl-1" type="text" value={paxDetail.sureName} />
                <br />
                {validErr.sureName && <span className="red">{validErr.sureName}</span>}
              </div>
              <div className="md-w-full">
                <label className="b-500 star" htmlFor="">
                  Date of Birth ({paxAgeType[currentPax.passengerType]})
                </label>
                <br />
                <input onChange={handleChange} placeholder="Date of Birth" min="1900-01-01" max={new Date().toJSON().slice(0, 10)} name="dob" className="inp-n-w pl-1" type="date" value={paxDetail.dob ? paxDetail.dob.split("/").reverse().join("-") : ""} />
                <br />
                {validErr.dob && <span className="red">{validErr.dob}</span>}
              </div>
              <div className="md-w-full p-r" style={{ width: "448px" }}>
                <label className="b-500" htmlFor="">
                  Contact No
                </label>
                <br />
                <div className="d-f f-spb c-gap-1 pointer md-mx-w-full">
                  <div className="inp-n-w mt-8px d-f f-j-c f-a-c c-gap-1" style={{ width: "109px", flexShrink: 0 }} onClick={() => setShowCountry(!showCountry)}>
                    <span>{flagAndCode}</span>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1L5 5L1 1" stroke="#2500BA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <input style={{ width: "100%" }} onChange={handleChange} placeholder="Contact Number" name="contactNo" className="inp-n-w pl-1" type="text" value={paxDetail.contactNo} />
                </div>
                {validErr.contactNo && <span className="red">{validErr.contactNo}</span>}
                {showCountry && (
                  <>
                    <CountryLists paxDetail={paxDetail} setPaxDetail={setPaxDetail} setCurrentCountry={setCurrentCountry} setShowCountry={setShowCountry} />
                    <div style={{ width: "100%", height: "100%", position: "fixed", top: 0, left: 0 }} onClick={() => setShowCountry(false)}></div>
                  </>
                )}
              </div>
              <div className="md-w-full">
                {/* <label className={`b-500${currentPax.passengerType == 1 ? " star" : ''}`} htmlFor="">Email</label><br /> */}
                <label className="b-500" htmlFor="">
                  Email
                </label>
                <br />
                <input onChange={handleChange} name="email" placeholder="Email Address" className="inp-n-w pl-1" type="email" value={paxDetail.email} />
              </div>
            </div>
            <h1 className="fs-20px b-500">Travel Document Information</h1>
            <div className="d-f f-wrap f-spb r-gap-1 mtb-2">
              <div className="md-w-full p-r">
                <label className="b-500 star" htmlFor="">
                  Nationality
                </label>
                <br />
                <input
                  placeholder="Nationality"
                  onChange={(e) => {
                    valid.nationality = false;
                    checkValid();
                    setPaxDetail({ ...paxDetail, nationality: e.target.value });
                  }}
                  onClick={() => setShowNationlity(!showNationality)}
                  value={paxDetail.nationality}
                  className="inp-n-w pl-1"
                  type="text"
                  required
                />
                {showNationality && (
                  <>
                    <NationalityList passportExpirayValid={passportExpirayValid} valid={valid} checkValid={checkValid} setShowNationlity={setShowNationlity} paxDetail={paxDetail} setPaxDetail={setPaxDetail} />
                    <div style={{ width: "100%", height: "100%", position: "fixed", top: 0, left: 0 }} onClick={() => setShowNationlity(false)}></div>
                  </>
                )}
              </div>
              <div className="md-w-full">
                <label className="b-500 star" htmlFor="">
                  Date of Issue
                </label>
                <br />
                <input onChange={handleChange} max={new Date().toJSON().slice(0, 10)} placeholder="Date Of Issue" name="dateOfIssue" className="inp-n-w pl-1" type="date" value={paxDetail.dateOfIssue ? paxDetail.dateOfIssue.split("/").reverse().join("-") : ""} />
                <br />
                {validErr.dateOfIssue && <span className="red">{validErr.dateOfIssue}</span>}
              </div>
              <div className="md-w-full">
                <label className="b-500 star" htmlFor="">
                  Passport Number
                </label>
                <br />
                <input onChange={handleChange} name="passportNo" placeholder="Passport Number" className="inp-n-w pl-1" type="text" value={paxDetail.passportNo} />
                <br />
                {validErr.passportNo && <span className="red">{validErr.passportNo}</span>}
              </div>
              <div className="md-w-full">
                <label className="b-500 star" htmlFor="">
                  Passport Expiry Date
                </label>
                <br />
                <input onChange={handleChange} min={minDate} placeholder="Date Of Expiry" name="dateOfExpiry" className="inp-n-w pl-1" type="date" value={paxDetail.dateOfExpiry ? paxDetail.dateOfExpiry.split("/").reverse().join("-") : ""} />
                <br />
                {validErr.dateOfExpiry && <span className="red">{validErr.dateOfExpiry}</span>}
              </div>
              <div className="md-w-full">
                <label className="b-500" htmlFor="">
                  Visa Number/(Optional)
                </label>
                <br />
                <input onChange={handleChange} name="visaNumber" placeholder="Visa Number" className="inp-n-w pl-1" type="text" value={paxDetail.visaNumber} />
              </div>
              <div className="md-w-full">
                <label className="b-500" htmlFor="">
                  Visa Expiry Date
                </label>
                <br />
                <input onChange={handleChange} min={minDate} placeholder="Date Of Expiry Of Visa" name="visaExpiryDate" className="inp-n-w pl-1" type="date" value={paxDetail.visaExpiryDate ? paxDetail.visaExpiryDate.split("/").reverse().join("-") : ""} />
                <br />
                {validErr.visaExpiryDate && <span className="red">{validErr.visaExpiryDate}</span>}
              </div>
            </div>
            <div className="d-f f-j-e md-f-col smd-f-a-c r-gap-1 c-gap-1">
              <button className="btn pointer" style={{ opacity: submit ? 1 : ".4" }} disabled={!submit}>
                Confirm Details
              </button>
              <button style={{ width: "171px" }} onClick={() => setCurrentPax("")} className="br-btn primary pointer">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function CountryLists({ setCurrentCountry, setShowCountry, paxDetail, setPaxDetail }) {
  const [searchKey, setSearchKey] = useState("");
  return (
    <div id="countryLists" className="list-items shadow p-a bg-white md-w-full" style={{ width: "150px", padding: ".5rem", borderRadius: "4px", zIndex: 1 }}>
      <input onChange={(e) => setSearchKey(e.target.value.toUpperCase())} type="text" placeholder="search" style={{ width: "100%", borderRadius: "4px", border: "2px solid #cecdcd" }} />
      <ul style={{ listStyle: "none", padding: 0, height: "200px", overflow: "scroll", marginTop: ".5rem" }}>
        {countries.map((item, index) => {
          if (item.name.toUpperCase().indexOf(searchKey) > -1) {
            return (
              <li
                key={index}
                onClick={() => {
                  setCurrentCountry({
                    dialCode: item.dial_code,
                    flag: item.flag,
                  });
                  setShowCountry(false);
                  setPaxDetail({ ...paxDetail, countryCode: item.dial_code });
                }}
                className="pointer"
              >
                {" "}
                {item.flag} {item.name}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
function NationalityList({ setShowNationlity, paxDetail, setPaxDetail, valid, checkValid, passportExpirayValid }) {
  const searchKey = paxDetail.nationality ? paxDetail.nationality.toUpperCase() : "";
  function makeUpperCase(item) {
    const name = item.split("");
    name[0] = name[0].toUpperCase();
    return name;
  }
  return (
    <div className="list-items shadow p-a bg-white  w-full" style={{ padding: ".5rem", borderRadius: "4px", zIndex: 1 }}>
      {/* <input onChange={(e) => setSearchKey(e.target.value.toUpperCase())} type="text" placeholder="search" style={{ width: "100%", borderRadius: "4px", border: '2px solid #cecdcd' }} /> */}
      <ul style={{ listStyle: "none", padding: 0, height: "200px", overflow: "scroll", marginTop: ".5rem" }}>
        {nationalityList.map((item, index) => {
          if (item.toUpperCase().indexOf(searchKey) > -1) {
            return (
              <li
                key={index}
                onClick={() => {
                  setShowNationlity(false);
                  valid.nationality = true;
                  setPaxDetail({ ...paxDetail, nationality: item });
                  passportExpirayValid(paxDetail.dateOfExpiry.split("/").reverse().join("-"), item);
                  checkValid();
                }}
                className="pointer"
              >
                {makeUpperCase(item)}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
function ShowDetail({ data }) {
  return (
    <div>
      <p>
        <span className="b-500">First Name : </span>
        <span className="fs-14px">{data.otherName}</span>
      </p>
      <p>
        <span className="b-500">Last Name : </span>
        <span className="fs-14px">{data.sureName}</span>
      </p>
      <p>
        <span className="b-500">Date of Birth : </span>
        <span className="fs-14px">{data.dob}</span>
      </p>
      <p>
        <span className="b-500">Nationality : </span>
        <span className="fs-14px">{data.nationality}</span>
      </p>
      <p>
        <span className="b-500">Mobile No : </span>
        <span className="fs-14px">{data.mobileNo}</span>
      </p>
      <p>
        <span className="b-500">Passport Number : </span>
        <span className="fs-14px">{data.passportNo}</span>
      </p>
      <p>
        <span className="b-500">Date of Issue : </span>
        <span className="fs-14px">{data.dateOfIssue}</span>
      </p>
      <p>
        <span className="b-500">Date of Expiry : </span>
        <span className="fs-14px">{data.dateOfExpiry}</span>
      </p>
      <p>
        <span className="b-500">Visa Number : </span>
        <span className="fs-14px">{data.visaNumber ? data.visaNumber : "not provided"}</span>
      </p>
      <p>
        <span className="b-500">Visa expiray Date : </span>
        <span className="fs-14px">{data.visaExpiryDate ? data.visaExpiryDate : "not provided"}</span>
      </p>
    </div>
  );
}

function Success({ data, getAddedPax }) {
  return (
    <div className="overlay">
      <div className="p-c p-1 br-r-8 bg-white txt-a-c" style={{ maxWidth: "fit-content", height: "fit-content" }}>
        <div>
          <img src={successImg} alt="" width="80px" />
        </div>
        <p className="primary mt-1">{data.message}</p>
        <p className="gray" style={{ marginTop: ".5rem" }}>
          {data.alertMessage}
        </p>
        {/* <button className='pointer msg-btn mt-1' onClick={getAddedPax}>ok</button> */}
      </div>
    </div>
  );
}
