import { useState } from "react";
import Loader from "../icons/Loader";
import Err from "./Err";
import { OpenEye, CloseEye } from "../icons/eye";
import successImg from "../../assets/images/success.png";
import { useNavigate } from "react-router-dom";
export default function ResetPassword() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const email = urlParams.get("email");
  const [submit, setSubmit] = useState(false);
  const [show, setShow] = useState({
    newPsd: true,
    conPsd: true,
  });
  const [err, setErr] = useState({
    newPassword: "",
    conPassword: "",
  });
  const [validation, setValidation] = useState({
    newPassword: false,
    conPassword: false,
  });
  const [sucMsg, setSucMsg] = useState(false);
  const [resMsg, setResMsg] = useState({ message: "" });
  const [fields, setField] = useState({
    newPassword: "",
    conPassword: "",
  });
  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    let errName = name;
    if (name == "newPassword") errName = "New password";
    else errName = "Confirm Password";
    let validateEmpty = value.length == 0 ? `${errName} field is empty` : false;
    let validate = "";
    switch (e.target.name) {
      case "newPassword":
        if (!validateEmpty) {
          validate = value.length < 8 ? "Password must be at least 8 characters" : false;
          if (!validate) {
            validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : "include symbol, numbers and Capital characters";
            if (!validate) {
              validate = fields.conPassword !== value ? "Password not match" : false;
              setErr({ ...err, conPassword: validate, newPassword: "" });
              if (!validate) setValidation({ ...validation, newPassword: true, conPassword: true });
              else setValidation({ ...validation, newPassword: false, conPassword: false });
            } else {
              setErr({ ...err, newPassword: validate });
              setValidation({
                ...validation,
                newPassword: false,
                conPassword: false,
              });
            }
          } else setErr({ ...err, newPassword: validate });
        } else setErr({ ...err, [name]: validateEmpty, conPassword: "" });
        break;
      case "conPassword":
        if (!validateEmpty) {
          validate = value.length < 8 ? "Password must be at least 8 characters" : false;
          if (!validate) {
            validate = /(?=.*\d)(?=.*\W)(?=.*[A-Z])/.test(value) ? false : "missing symbol, numbers and Capital characters";
            if (!validate) {
              validate = fields.newPassword !== value ? "password not match" : false;
              if (!validate) {
                setErr({ ...err, [name]: "" });
                setValidation({
                  ...validation,
                  newPassword: true,
                  conPassword: true,
                });
              } else {
                setValidation({
                  ...validation,
                  newPassword: false,
                  conPassword: false,
                });
                setErr({ ...err, [name]: validate });
              }
            }
          }
        } else setErr({ ...err, [name]: validateEmpty });

        break;
    }
    if (validateEmpty) {
      setValidation({ ...validation, [name]: false });
    }
    setField({ ...fields, [e.target.name]: e.target.value });
  }
  function handleSubmit(e) {
    e.preventDefault();
    postNewPsd();
  }
  async function postNewPsd() {
    setSubmit(true);
    try {
      const response = await fetch(process.env.REACT_APP_DOAMIN + "/Authenticate/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: email,
          newPassword: fields.newPassword,
          token: token,
        }),
      });

      const result = await response.json();
      if (response.status == 200) {
        // window.location.assign('/');
        setSubmit(false);
        setSucMsg({
          suc: true,
        });
      } else {
        throw result;
      }
    } catch (err) {
      setSubmit(false);
      setResMsg({
        message: err.message,
        alertMessage: err.alertMessage,
      });
    } finally {
      setTimeout(() => {
        localStorage.clear();
        window.location.assign("/");
      }, 5000);
    }
  }
  const style = {
    input: {
      background: "var(--inputGray)",
      color: "black",
      border: "none",
      maxWidth: "352px",
      height: "48px",
      borderRadius: "8px",
    },
  };
  return (
    <div className="p-8 sm-p-1 br-r-12 p-c" style={{ maxWidth: "416px", height: "fit-content", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}>
      <div className="sm-p-1 bg-white br-r-12">
        <h1 className="fs-20px b-500 primary mb-1">Set your new password</h1>
        <p className="gray mb-1-5">
          Reinforce your account's security <br />
          by set a new password.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mt-1-5">
            <label htmlFor="" className="b-500">
              New Password
            </label>{" "}
            <br />
            <div className="mt-8px" style={{ position: "relative" }}>
              <input onChange={handleChange} className="pl-1-5 w-full" type={show.newPsd ? "password" : "text"} style={style.input} name="newPassword" placeholder="New Password" required />
              <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, newPsd: !show.newPsd })} style={{ position: "absolute", right: 0, top: 0, bottom: 0, right: "1rem" }}>
                {show.newPsd ? <CloseEye /> : <OpenEye />}
              </span>
            </div>
            {err && <span className="red">{err.newPassword}</span>}
          </div>
          <div className="mt-1-5">
            <label htmlFor="" className="b-500">
              Confirm Password
            </label>{" "}
            <br />
            <div className="mt-8px" style={{ position: "relative" }}>
              <input onChange={handleChange} className="pl-1-5 w-full" type={show.conPsd ? "password" : "text"} style={style.input} name="conPassword" placeholder="Confirm Password" required />
              <span className="pointer d-f f-a-c" onClick={() => setShow({ ...show, conPsd: !show.conPsd })} style={{ position: "absolute", right: 0, top: 0, bottom: 0, right: "1rem" }}>
                {show.conPsd ? <CloseEye /> : <OpenEye />}
              </span>
            </div>
            {err && <span className="red">{err.conPassword}</span>}
          </div>
          <div className="d-f c-gap-1 f-wrap r-gap-1 f-j-c mt-2">
            <button className="p-r btn pointer" disabled={!(validation.newPassword && validation.conPassword)} style={{ opacity: validation.newPassword && validation.conPassword ? 1 : ".4", width: "171px", color: submit ? "#ffffff54" : "white" }}>
              Reset Password {submit && <Loader color="black" />}
            </button>
            <button className="br-btn pointer" style={{ width: "171px" }} onClick={() => navigate("/")}>
              Cancel
            </button>
          </div>
        </form>
        {resMsg.message && (
          <div className="overlay">
            <Err data={resMsg} click={() => setResMsg({ message: "" })} />
          </div>
        )}
        {sucMsg && <Success msg={sucMsg.msg} />}
      </div>
    </div>
  );
}

function Success({ msg }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "450px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">Password has been changed Successfully</p>
            <p>please login with new password</p>
            {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
