import { useState } from "react";
import Loader from "../icons/Loader";
import Err from "./Err";
import successImg from "../../assets/images/success.png";
import { useLocation, useNavigate } from "react-router-dom";
export default function Forgot() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const [resErr, setResErr] = useState({ message: "" });
  const [success, setSuccess] = useState(false);
  const style = {
    input: {
      background: "var(--inputGray)",
      color: "black",
      border: "none",
      width: "100%",
      height: "48px",
      borderRadius: "8px",
    },
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);
    try {
      const response = await fetch(process.env.REACT_APP_DOAMIN + "/Authenticate/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      const result = await response.json();
      // setResErr(result.message)
      if (response.status == 200) {
        setSuccess(true);
      } else {
        throw result;
      }
    } catch (err) {
      setSubmit(false);
      setResErr({
        message: err.message,
        alertMessage: err.alertMessage,
      });
    }
  }
  return success ? (
    <Success />
  ) : (
    <div className="overlay remove-overlay">
      <div className="p-1 br-r-12 p-c" style={{ maxWidth: "416px", height: "fit-content" }}>
        <div className="p-8 sm-p-1 bg-white br-r-12 show-boxshadow">
          <h1 className="fs-20px b-500 primary mb-1 sm-txt-c">{state.isActive ? "Set" : "Reset"} your Password</h1>
          <p className="gray mb-1-5 sm-txt-c">Please provide your email address to {state.isActive ? "set" : "reset"} your password.</p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="" className="b-500">
                Email Address
              </label>{" "}
              <br />
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="mt-8px pl-1-5"
                type="email"
                style={style.input}
                name="email"
                placeholder="Email Address"
                required
              />
            </div>

            <div className="txt-a-c mt-2 d-f f-col r-gap-1 f-a-c">
              <button className="p-r btn pointer" style={{ width: "171px", color: submit ? "#ffffff54" : "white" }}>
                Send {state.isActive ? "" : "Reset"} Link {submit && <Loader color="black" />}
              </button>
              <button className="br-btn" style={{ width: "171px" }} onClick={() => navigate("/")}>
                Cancel
              </button>
            </div>
          </form>
          {resErr.message && (
            <div className="overlay">
              <Err data={resErr} click={() => setResErr({ message: "" })} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
function Success() {
  return (
    <div className="overlay">
      <div className="p-1 br-r-12 p-c" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="bg-white p-1 br-r-12 txt-a-c">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <h1 className="fs-20px b-500 primary">Your password reset link sent successfully!</h1>
          <p>Please check you email for instructions.</p>
          <div className="txt-a-c mt-1">
            <button onClick={() => window.location.assign("/")} className="btn pointer" style={{ width: "80px", height: "40px" }}>
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
