import { useParams, useLocation } from "react-router-dom";
import logo from "../../assets/images/SubhamLogo.png";
import { useEffect, useState } from "react";
import { getPaxDetail } from "../../api/api";
import Loader from "../icons/Loader";
import { useMediaQuery } from "react-responsive";
const html2pdf = require("html2pdf.js");
export default function Ticket({ data, id }) {
  const url = useLocation();
  const [loader, setLoader] = useState(true);
  const paxType = {
    1: "ADT",
    2: "CHLD",
    3: "INFT",
  };
  return (
    <div style={{ width: "700px", margin: "1rem auto" }} id={"ticket" + id}>
      <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
        <img src={logo} width="100px" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 1rem",
        }}
      >
        <div>
          <p style={{ display: "flex" }}>
            <span style={{ fontSize: "12px" }}>PASSENGER:&nbsp;</span>
            <span style={{ fontSize: "12px", fontWeight: 700 }}>
              {data.sureName} {data.otherName}({paxType[data.passengerType]})
            </span>
          </p>
          <p style={{ display: "flex" }}>
            <span style={{ fontSize: "12px" }}>BOOKING REFERENCE:&nbsp;</span>
            <span style={{ fontSize: "12px", fontWeight: 700 }}>
              {data.bookingId ? data.bookingId.join("-") : ""}-{id}
            </span>
          </p>
          <p style={{ display: "flex" }}>
            <span style={{ fontSize: "12px" }}>DATE OF ISSUE:&nbsp;</span>
            <span style={{ fontSize: "12px", fontWeight: 700 }}>{data.viewDetails.dateOfIssue}</span>
          </p>
          <p style={{ display: "flex" }}>
            <span style={{ fontSize: "12px" }}>BOOKED BY:&nbsp;</span>
            <span style={{ fontSize: "12px", fontWeight: 700 }}>{data.viewDetails.userName.toUpperCase()}</span>
          </p>
          <p style={{ display: "flex", fontSize: "12px", fontWeight: 600 }}>
            {data.viewDetails.phoneNumber},&nbsp;
            {data.viewDetails.email}
          </p>
        </div>
        <div>
          <p style={{ fontSize: "12px" }}>ISSUING OFFICE:</p>
          <p style={{ fontSize: "12px", fontWeight: 700 }}>SUBHAM FREIGHT CARRIERS INDIA 

(PVT)LTD</p>
          <p style={{ fontSize: "12px", fontWeight: 700 }}>www.sailsubham.com, ONLINE BOOKING</p>
        </div>
      </div>
      <h1
        style={{
          fontSize: "1rem",
          fontWeight: 700,
          padding: "8px 24px",
          marginTop: "1.5rem",
          backgroundColor: "#DF7435",
          color: "white",
        }}
      >
        ELECTRONIC TICKET RECEIPT
      </h1>
      <div style={{ padding: "1rem" }}>
        <div>
          <p style={{ fontSize: "8px" }}>Your electronic ferry ticket is securely stored in our reservations system. It is mandatory to carry this document along with any other travel-related documents specified by local and international security, customs, and passport control authorities. The ferry service will not assume responsibility for any consequences arising from your failure to possess the necessary travel documents.</p>
          <p style={{ fontSize: "8px", marginTop: "1rem" }}>Our check-in counters open 3 hours before the scheduled ferry departure and close 1 hour before departure globally, unless otherwise specified. You must check in during this timeframe to avoid any denial of boarding.</p>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <table className="w-full" style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#DF7435", height: "34px" }}>
                <th>
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    From
                  </p>
                </th>
                <th>
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    To
                  </p>
                </th>
                <th>
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Ferry
                  </p>
                </th>
                <th>
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Departure
                  </p>
                </th>
                <th>
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Arrival
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#D9D9D9", height: "50px" }}>
                <td>
                  <p style={{ fontSize: "12px", fontWeight: 600 }}>{data.viewDetails ? data.viewDetails.voyageFromName : ""}</p>
                </td>
                <td>
                  <p style={{ fontSize: "12px", fontWeight: 600 }}>{data.viewDetails ? data.viewDetails.voyageToName : ""}</p>
                </td>
                <td>
                  <p style={{ fontSize: "12px", fontWeight: 600 }}>{data.viewDetails ? data.viewDetails.ferryId : ""}</p>
                </td>
                <td>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      display: "flex",
                      flexFlow: "column",
                    }}
                  >
                    {data.viewDetails ? data.viewDetails.voyageFromTime : ""} <br />
                    <span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravel : ""}</span>
                  </p>
                </td>
                <td>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      display: "flex",
                      flexFlow: "column",
                    }}
                  >
                    {data.viewDetails ? data.viewDetails.voyageToTime : ""} <br />
                    <span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravel : ""}</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              columnGap: "100px",
              display: "flex",
              marginTop: "8px",
              paddingLeft: "1rem",
            }}
          >
            <div>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>Class:&nbsp;</span>
                <span style={{ fontSize: "8px" }}>{data.viewDetails.className}</span>
              </p>
              {/* <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>Baggage:&nbsp;</span>
                <span style={{ fontSize: "8px" }}>
                  23kg per pax
                   Hand baggage 5kg 
                </span>
              </p> */}
            </div>
            <div>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>Operated by:&nbsp;</span>
                <span style={{ fontSize: "8px" }}>SAIL SUBHAM</span>
              </p>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>Marketed by:&nbsp;</span>
                <span style={{ fontSize: "8px" }}> SAIL SUBHAM</span>
              </p>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>Booking status (1):&nbsp;</span>
                <span style={{ fontSize: "8px" }}>Ok</span>
              </p>
            </div>
            <div>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>NVB (2):&nbsp;</span>
                <span style={{ fontSize: "8px" }}>{data.viewDetails.dateOfTravel}</span>
              </p>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>NVA (3):&nbsp;</span>
                <span style={{ fontSize: "8px" }}>{data.viewDetails.dateOfTravel}</span>
              </p>
              <p style={{ display: "flex" }}>
                <span style={{ fontSize: "8px", fontWeight: 700 }}>Voyage duration:&nbsp;</span>
                <span style={{ fontSize: "8px" }}>{data.viewDetails.duration}</span>
              </p>
            </div>
          </div>
        </div>
        {data.viewDetails.type == 2 && (
          <div className="mt-8px">
            <table className="w-full" style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#DF7435", height: "34px" }}>
                  <th>
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      From
                    </p>
                  </th>
                  <th>
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      To
                    </p>
                  </th>
                  <th>
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Ferry
                    </p>
                  </th>
                  <th>
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Departure
                    </p>
                  </th>
                  <th>
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      Arrival
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#D9D9D9", height: "50px" }}>
                  <td>
                    <p style={{ fontWeight: 600, fontSize: "12px" }}>{data.viewDetails ? data.viewDetails.voyageFromReturnName : ""}</p>
                  </td>
                  <td>
                    <p style={{ fontWeight: 600, fontSize: "12px" }}>{data.viewDetails ? data.viewDetails.voyageToReturnName : ""}</p>
                  </td>
                  <td>
                    <p style={{ fontWeight: 600, fontSize: "12px" }}>{data.viewDetails ? data.viewDetails.ferryIdReturn : ""}</p>
                  </td>
                  <td>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "12px",
                        display: "flex",
                        flexFlow: "column",
                      }}
                    >
                      {data.viewDetails ? data.viewDetails.voyageFromTimeReturn : ""} <br />
                      <span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravelReturn : ""}</span>
                    </p>
                  </td>
                  <td>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "12px",
                        display: "flex",
                        flexFlow: "column",
                      }}
                    >
                      {data.viewDetails ? data.viewDetails.voyageToTimeReturn : ""} <br />
                      <span style={{ fontSize: "10px" }}>{data.viewDetails ? data.viewDetails.dateOfTravelReturn : ""}</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                columnGap: "100px",
                display: "flex",
                marginTop: "8px",
                paddingLeft: "1rem",
              }}
            >
              <div>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>Class:&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>{data.viewDetails.className}</span>
                </p>
                {/* <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>Baggage:&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>23kg per pax </span>
                </p> */}
              </div>
              <div>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>Operated by:&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>SAIL SUBHAM</span>
                </p>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>Marketed by:&nbsp;</span>
                  <span style={{ fontSize: "8px" }}> SAIL SUBHAM</span>
                </p>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>Booking status (1):&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>Ok</span>
                </p>
              </div>
              <div>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>NVB (2):&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>{data.viewDetails.dateOfTravelReturn}</span>
                </p>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>NVA (3):&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>{data.viewDetails.dateOfTravelReturn}</span>
                </p>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "8px", fontWeight: 700 }}>Voyage duration:&nbsp;</span>
                  <span style={{ fontSize: "8px" }}>{data.viewDetails.durationReturn}</span>
                </p>
              </div>
            </div>
          </div>
        )}
        <hr style={{ marginTop: "8px" }} />
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            display: "flex",
            columnGap: "1rem",
          }}
        >
          <p>
            <span style={{ fontSize: "8px", fontWeight: 500 }}>(1) Ok = </span>
            <span style={{ fontSize: "8px", fontWeight: 700 }}>Confirmed</span>
          </p>
          <p>
            <span style={{ fontSize: "8px", fontWeight: 500 }}>(2) NVB = </span>
            <span style={{ fontSize: "8px", fontWeight: 700 }}>Not valid before</span>
          </p>
          <p>
            <span style={{ fontSize: "8px", fontWeight: 500 }}>(3) NVA = </span>
            <span style={{ fontSize: "8px", fontWeight: 700 }}>Not valid after</span>
          </p>
        </div>
        <hr />
        {/* <div className="html2pdf__page-break"></div> */}
        <h1
          style={{
            fontSize: "1rem",
            fontWeight: 700,
            marginTop: "1.5rem",
            padding: "8px 24px",
            backgroundColor: "#DF7435",
            color: "white",
          }}
        >
          Optional Services
        </h1>
        <div style={{ background: "#D9D9D9", padding: "8px 1rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h1 style={{ fontSize: "12px", fontWeight: 600 }}>Preferred Seat</h1>
              <div className="flex" style={{ marginTop: ".5rem" }}>
                <p style={{ fontSize: "10px", fontWeight: 600 }}>
                  {data.viewDetails.voyageFrom} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageTo}:
                </p>
                <p style={{ fontSize: "10px", fontWeight: 700 }}>&nbsp; {data.seatOneWay}</p>
              </div>
              {data.viewDetails.type == 2 && (
                <div className="flex">
                  <p style={{ fontSize: "10px", fontWeight: 600 }}>
                    {data.viewDetails.voyageFromReturn} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageToReturn}:
                  </p>
                  <p style={{ fontSize: "10px", fontWeight: 700 }}>&nbsp; {data.seatReturnWay}</p>
                </div>
              )}
            </div>
            <div>
              <h1 style={{ fontSize: "12px", fontWeight: 600 }}>Extra Luggage</h1>
              <div>
                <p style={{ fontSize: "10px", fontWeight: 600 }}>
                  {data.viewDetails.voyageFrom} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageTo}: <span style={{ fontSize: "10px" }}>{data.luggageOneWay} KG</span>{" "}
                </p>
                {data.viewDetails.type == 2 && (
                  <p style={{ fontSize: "10px", fontWeight: 600 }}>
                    {data.viewDetails.voyageFromReturn} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageToReturn}: <span style={{ fontSize: "10px" }}>{data.luggageReturnWay} KG</span>{" "}
                  </p>
                )}
              </div>
            </div>
            <div>
              <h1 style={{ fontSize: "12px", fontWeight: 600 }}>Preferred Meal</h1>
              <div>
                <p style={{ fontSize: "10px", fontWeight: 600 }}>
                  {data.viewDetails.voyageFrom} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageTo}:
                </p>
                <div style={{ fontSize: "10px", fontWeight: 700 }}>
                  {data.menuOneWay.length > 0 &&
                    data.menuOneWay.map((item, index) => (
                      <p key={index} style={{ fontSize: "10px", fontWeight: 700 }}>
                        {item.menuName} x {item.quantity}
                      </p>
                    ))}
                </div>
              </div>
              {data.viewDetails.type == 2 && (
                <div style={{ marginTop: "8px" }}>
                  <p style={{ fontSize: "10px", fontWeight: 600 }}>
                    {data.viewDetails.voyageFromReturn} <span style={{ fontSize: "10px" }}>to</span> {data.viewDetails.voyageToReturn}:
                  </p>
                  <div style={{ fontSize: "10px", fontWeight: 700 }}>
                    {data.menuReturnWay.length > 0 &&
                      data.menuReturnWay.map((item, index) => (
                        <p key={index} style={{ fontSize: "10px", fontWeight: 700 }}>
                          {item.menuName} x {item.quantity}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <h1
          style={{
            fontSize: "1rem",
            marginTop: "1.5rem",
            fontWeight: 700,
            padding: "8px 24px",
            backgroundColor: "#DF7435",
            color: "white",
          }}
        >
          Fare Details
        </h1>
        <div className="d-f f-spb" style={{ background: "#D9D9D9", padding: "8px 1rem" }}>
          <div>
            <h1 className="b-500 fs-12px">Voyage</h1>
            <p className="flex items-center">
              <span style={{ fontSize: "10px", fontWeight: 600 }}>Base Fare: &nbsp;</span>
              <span style={{ fontSize: "10px" }}>
                {data.fareBreakDown.travel.baseFare.toLocaleString("en-GB", {
                  style: "currency",
                  currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                })}
              </span>
            </p>
            <p style={{ display: "flex" }}>
              <span style={{ fontSize: "10px", fontWeight: 600 }}>Taxes: &nbsp;</span>
            </p>
            {data.fareBreakDown.travel.taxes.length > 0 &&
              data.fareBreakDown.travel.taxes.map((tax, index) => (
                <p className="flex items-center" key={index}>
                  <span style={{ fontSize: "10px" }}>{tax.taxCode}: &nbsp;</span>
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    {tax.amount.toLocaleString("en-GB", {
                      style: "currency",
                      currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                    })}
                  </span>
                </p>
              ))}
            <br />
            <h1 className="b-500 fs-12px">Port Service Charges</h1>
            {data.fareBreakDown.arrPort && (
              <div>
                <div style={{ marginTop: ".5rem" }}>
                  <p className="b-500 fs-12px">arrival Port Charges</p>
                  <p className="flex items-center" style={{ display: "flex", marginTop: ".25rem" }}>
                    <span className="b-600" style={{ fontSize: "10px" }}>
                      base Fare: &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: 600,
                        fontFamily: "Arial, sans-serif",
                      }}
                    >
                      {data.fareBreakDown.arrPort.baseFare.toLocaleString("en-GB", {
                        style: "currency",
                        currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                      })}
                    </span>
                  </p>
                  {data.fareBreakDown.arrPort.taxes.map((charge, index) => (
                    <p key={index} className="d-f">
                      <span style={{ fontSize: "10px" }}>{charge.taxCode}: &nbsp;</span>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: 600,
                          fontFamily: "Arial, sans-serif",
                        }}
                      >
                        {charge.amount.toLocaleString("en-GB", {
                          style: "currency",
                          currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                        })}
                      </span>
                    </p>
                  ))}
                </div>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "10px", fontWeight: 600 }}>
                    Total:{""}
                    {data.fareBreakDown.arrPort.total.toLocaleString("en-GB", {
                      style: "currency",
                      currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                    })}
                  </span>
                </p>
              </div>
            )}
            {data.fareBreakDown.depPort && (
              <div style={{ marginTop: ".5rem" }}>
                <p className="b-500 fs-12px">Departure Port Charge</p>
                <p className="flex items-center" style={{ display: "flex", marginTop: ".25rem" }}>
                  <span className="b-600" style={{ fontSize: "10px" }}>
                    base Fare: &nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                    }}
                  >
                    {data.fareBreakDown.depPort.baseFare.toLocaleString("en-GB", {
                      style: "currency",
                      currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                    })}
                  </span>
                </p>
                {data.fareBreakDown.depPort.taxes.map((tax, index) => (
                  <div key={index} style={{ marginTop: ".5rem" }}>
                    <p className="items-center" style={{ display: "flex", marginTop: ".5rem" }}>
                      <span className="b-600" style={{ fontSize: "10px" }}>
                        {tax.taxCode}: &nbsp;
                      </span>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: 600,
                        }}
                      >
                        {tax.amount.toLocaleString("en-GB", {
                          style: "currency",
                          currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                        })}
                      </span>
                    </p>
                  </div>
                ))}
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "10px", fontWeight: 600 }}>
                    Total:{" "}
                    {data.fareBreakDown.depPort.total.toLocaleString("en-GB", {
                      style: "currency",
                      currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                    })}
                  </span>
                </p>
              </div>
            )}
            <br />
            <div>
              <p style={{ fontSize: "12px", fontWeight: 600 }}>
                Base Fare:&nbsp;
                {(data.fareBreakDown.travel.baseFare + data.fareBreakDown.arrPort.baseFare + data.fareBreakDown.depPort.baseFare + data.fareBreakDown.food.baseFare + data.fareBreakDown.luggage.baseFare).toLocaleString("en-GB", {
                  style: "currency",
                  currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                })}
              </p>
              <p style={{ fontSize: "12px", fontWeight: 600 }}>
                Total Voyage Fare:&nbsp;
                {(data.fareBreakDown.travel.total + data.fareBreakDown.arrPort.total + data.fareBreakDown.depPort.total + data.fareBreakDown.food.total + data.fareBreakDown.luggage.total).toLocaleString("en-GB", {
                  style: "currency",
                  currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                })}
              </p>
            </div>
          </div>
          <div>
            <h1 className="b-500 fs-12px">Extra Luggage </h1>
            <p className="flex items-center">
              <span style={{ fontSize: "10px", fontWeight: 600 }}>Base Fare: &nbsp;</span>
              <span style={{ fontSize: "10px" }}>
                {data.fareBreakDown.luggage.baseFare.toLocaleString("en-GB", {
                  style: "currency",
                  currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                })}
              </span>
            </p>
            <p style={{ display: "flex" }}>
              <span style={{ fontSize: "10px", fontWeight: 600 }}>Taxes: &nbsp;</span>
            </p>

            {data.fareBreakDown.luggage.taxes.length > 0 &&
              data.fareBreakDown.luggage.taxes.map((tax, index) => (
                <p key={index} className="flex items-center">
                  <span style={{ fontSize: "10px" }}>{tax.taxCode}: &nbsp;</span>
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                    }}
                  >
                    {tax.amount.toLocaleString("en-GB", {
                      style: "currency",
                      currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                    })}
                  </span>
                </p>
              ))}
          </div>
          <div>
            <h1 className="b-500 fs-12px">Pre-order Meal</h1>
            <p className="flex items-center">
              <span style={{ fontSize: "10px", fontWeight: 600 }}>Base Fare: &nbsp;</span>
              <span style={{ fontSize: "10px" }}>
                {data.fareBreakDown.food.baseFare.toLocaleString("en-GB", {
                  style: "currency",
                  currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                })}
              </span>
            </p>
            <p style={{ display: "flex" }}>
              <span style={{ fontSize: "10px", fontWeight: 600 }}>Taxes: &nbsp;</span>
            </p>

            {data.fareBreakDown.food.taxes.length > 0 &&
              data.fareBreakDown.food.taxes.map((tax, index) => (
                <p key={index} className="flex items-center">
                  <span style={{ fontSize: "10px" }}>{tax.taxCode}: &nbsp;</span>
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: 600,
                    }}
                  >
                    {tax.amount.toLocaleString("en-GB", {
                      style: "currency",
                      currency: data.viewDetails.chargeCurrency ? data.viewDetails.chargeCurrency : "LKR",
                    })}
                  </span>
                </p>
              ))}
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
        <h1
          style={{
            fontSize: "1rem",
            bold: 700,
            marginTop: "1.5rem",
            padding: "8px 24px",
            backgroundColor: "#DF7435",
            color: "white",
          }}
        >
          Passenger Notes
        </h1>
        <div style={{ background: "#D9D9D9", paddingBottom: "1rem" }}>
          <div style={{ padding: "8px 1rem" }}>
            <p style={{ fontSize: "8px" }}>Your electronic ferry ticket is securely stored in our reservations system. It is mandatory to carry this document along with any other travel-related documents specified by local and international security, customs, and passport control authorities. The ferry service will not assume responsibility for any consequences arising from your failure to possess the necessary travel documents. </p>
            <p style={{ fontSize: "8px", marginTop: ".5rem" }}>We recommend arriving at the Port 3 hours before the scheduled departure time. The Check-in counter will close 45 minutes before the scheduled departure time. Customers and travel agents are advised to provide accurate passenger information at the time of booking. Any incorrect details, including spelling errors in names, passport information, or visa details, will incur a correction fee of ₹1,000/- (or LKR 3,500/- or USD 12) at the time of check-in</p>
            <h1 className="fs-8px b-600" style={{ marginTop: ".5rem" }}>
              Refund policy:
            </h1>
            <p style={{ fontSize: "8px", marginTop: ".25rem" }}>Cancellation 72 hours or more before departure: 80% of the ticket amount will be refunded. Cancellation between 24 hours to 72 hours before departure: 70% of the ticket amount will be refunded. Cancellation within 24 hours of departure: No refund will be provided. </p>
            <h1 className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
              Reschedule Policy:
            </h1>
            <p style={{ fontSize: "8px", marginTop: ".25rem" }}>Rescheduling 72 hours before departure: A fee Equivalent to 20% of the ticket amount will apply. Rescheduling between 24 to 72 hours before departure: A fee equivalent to 30% of ticket amount will apply. Rescheduling within 24 hours of departure: Rescheduling is not permitted.</p>
            <h1 className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
              Voyage Cancellation Policy:
            </h1>
            <p style={{ fontSize: "8px", marginTop: ".25rem" }}>In cases where the Voyage is Cancelled by the Management, customers will be entitled to one of the following options without incurring any additional charges: Full Refund: 100% of the ticket amount will be refunded Rescheduling: The ticket can be rescheduled for a future date.</p>
            <h1 className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
              Hand Luggage Policy:
            </h1>
            <p style={{ fontSize: "8px", marginTop: ".5rem" }}>Passengers are allowed to carry one carry-on bag along with a personal item. The carry-on bag, including the handles and wheels, must not exceed the dimension of 22 x 14 x 9 inches (56 x 36 x 23 cm), maximum weight of 22lbs/ 10 kg.</p>
            <h1 className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
              Checked Bag Policy
            </h1>
            <ul>
              <li className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
                Bag Limitation:
              </li>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}>Maximum 3 bags allowed.</p>
              <li className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
                Checked Bag Allowances and fees
              </li>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}>For each checked bag, only one bag per category is allowed. For example, if 20 kg luggage is booked only 1 luggage bag is allowed and if 40 kg luggage is booked 2 luggage bags are allowed and if 50 kg luggage is booked 3 luggage bags will be allowed.</p>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}>All the checked baggage must be pre-booked online. Extra checked baggage cannot be added at the time of check-in. </p>
              <li className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
                Baggage weight and size
              </li>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}>Total bag dimension: (length + width + height): 62 in / 158 cm</p>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}>Maximum Weight per bag: 44 lbs / 20 kgs</p>
              <li className="fs-8px b-600 mt-1" style={{ marginTop: ".5rem" }}>
                Oversized Baggage:
              </li>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}> No charges will be levied for Bag size up-to 5 cubic feet. </p>
              <p style={{ fontSize: "8px", marginTop: ".5rem" }}>INR 400 will be charged per cubic feet for bag size exceeding 5 cubic feet.</p>
            </ul>
            <h1 className="fs-8px b-500 mt-1"></h1>
          </div>
          <p className="text-center" style={{ fontSize: "8px", fontWeight: 700 }}>
            This advisory is generated by the Subham Freight Carriers India Pvt Ltd computer system and does not require a signature. Your ticket record is electronically stored with SAIL SUBHAM
          </p>
        </div>
      </div>
      <div
        style={{
          bold: 700,
          display: "flex",
          justifyContent: "center",
          columnGap: "1rem",
          alignItems: "center",
          height: "57px",
          backgroundColor: "#DF7435",
          color: "white",
        }}
      >
        <p style={{ fontSize: "8px", fontWeight: 700 }}>For the further clarifications:</p>
        <p style={{ display: "flex" }}>
          <span style={{ fontSize: "8px", fontWeight: 700 }}>Phone:&nbsp;</span>
          <span style={{ fontSize: "8px" }}>+91 74488 43535</span>
        </p>
        {/* <p style={{ display: "flex" }}>
          <span style={{ fontSize: "8px", fontWeight: 700 }}>SriLanka:&nbsp;</span>
          <span style={{ fontSize: "8px" }}>+94 11 617 2547</span>
        </p> */}
        <p style={{ display: "flex" }}>
          <span style={{ fontSize: "8px", fontWeight: 700 }}>Email:&nbsp;</span>
          <span style={{ fontSize: "8px" }}>customercare@sailsubham.com
          </span>
        </p>
      </div>
    </div>
  );
}
