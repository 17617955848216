import { useEffect, useState } from "react";
import seat from "../../assets/images/seat.png";
import successImg from "../../assets/images/success.png";
import failImg from "../../assets/images/fail.png";
import { Link, useLocation } from "react-router-dom";
import { getSeats, updateSeats } from '../../api/api';
import { useMediaQuery } from 'react-responsive';
import Loader from '../icons/Loader';
const BLUE = "#DF7435";
const GRAY = "#D9D9D9";
const GREEN = "#95BA00";
export default function SeatSelection() {
  const isMob = useMediaQuery({ query: "(max-width:900px)" });
  const { state } = useLocation();
  const bookingId = state ? state : JSON.parse(localStorage.getItem("bookingId"));
  const [seatListOneWay, setSeatListOneWay] = useState([]);
  const [seatListReturnWay, setSeatListReturnWay] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState([]);
  const [returnSelectedSeat, setReturnSelectedSeat] = useState([]);
  const [round, setRound] = useState(false);
  const [roundMsg, setRoundMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paxCount, setPaxCount] = useState(0);
  const [port, setPort] = useState({
    fromPort: "",
    toPort: "",
  });
  const [msg, setMsg] = useState({ status: "success", message: "", alertMessage: "", isBtn: false });
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const style = {
    wrapper: {
      width: "512px",
      height: "360px",
      boxShadow: " 0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "1rem",
    },
    btn: {
      width: "196px",
      height: "48px",
      textDecoration: "none",
      border: "1px solid var(--primaryColor)",
    },
    box: {
      width: "48px",
      height: "48px",
      borderRadius: "4px",
    },
  };
  const lists = [];
  useEffect(() => {
    setLoader(true);
    getSeats({ bookingId: bookingId }).then((data) => {
      if (data) {
        setSeatListOneWay(data.seatInfoOneWay);
        setSeatListReturnWay(data.seatInfoReturnWay);
        setPaxCount(data.paxCount);
        setPort({
          fromPort: data.fromport,
          toPort: data.toPort,
        });
      }
      setLoader(false);
    });
  }, []);
  function handleConfirm() {
    async function handleUpdateSeat() {
      const data = {
        bookingId: bookingId,
        seatItems: selectedSeat,
        seatItemsReturn: returnSelectedSeat,
      };
      try {
        const response = await fetch(process.env.REACT_APP_DOAMIN + "/seatSelection/seatUpdate", {
          method: "POST",
          headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        });

        const result = await response.json();
        if (response.status == 200) {
          setMsg({ status: "success", message: result.message, alertMessage: result.alertMessage });
          setTimeout(() => {
            setMsg({ message: "" });
            setBookingSuccess(true);
          }, 3000);
        } else {
          throw result;
        }
      } catch (err) {
        setLoader(false);
        setMsg({
          status: "failed",
          message: err.message,
          alertMessage: err.alertMessage,
          isBtn: true,
        });
      }
    }
    if (seatListReturnWay.length > 0 && !round) {
      if (seatListOneWay.length == 0 && seatListReturnWay.length > 0) {
        setLoader(true);
        handleUpdateSeat();
      } else {
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
          setRoundMsg(true);
        }, 2000);
      }
    } else {
      setLoader(true);
      handleUpdateSeat();
    }
  }
  return (
    <div className="sm-p-1">
      <h1 className="fs-20px b-500">
        Book Your Preferred Seat (Optional) <span className="primary fs-20px b-500">{round ? port.toPort : port.fromPort}</span> to <span className="primary fs-20px b-500">{round ? port.fromPort : port.toPort}</span>
      </h1>
      <p>Choose comfort, secure your spot! Book your preferred seat for a personalized and enjoyable journey onboard.</p>
      <div className="d-f f-spb p-8 m-a mt-2 sm-f-col sm-r-gap-1" style={{ maxWidth: "832px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)", borderRadius: " 4px" }}>
        <div className="d-f f-a-c c-gap-1">
          <div style={{ ...style.box, backgroundColor: BLUE }}></div>
          <span>Available seat</span>
        </div>
        <div className="d-f f-a-c c-gap-1">
          <div style={{ ...style.box, backgroundColor: GRAY }}></div>
          <span>Unavailable seat</span>
        </div>
        <div className="d-f f-a-c c-gap-1">
          <div style={{ ...style.box, backgroundColor: GREEN }}></div>
          <span>Selected seat</span>
        </div>
      </div>
      <div className="m-a sm-w-full" style={{ width: "fit-content" }}>
        <div className="m-a p-8 mt-2 sm-w-full" style={{ width: "fit-content", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)", borderRadius: "1rem" }}>
          <div className="c-gap-1 r-gap-1 mt-2 sm-w-full sm- sm-f-j-c sm-gap-5px " style={{ display: "grid", gridTemplateColumns: `repeat(13, ${isMob ? "20px" : "48px"})`, width: "fit-content" }}>
            {(seatListOneWay.length && seatListReturnWay.length) > 0 ? (
              <>
                {!round && seatListOneWay.length > 0 && seatListOneWay.map((item, index) => <Seat key={index} paxCount={paxCount} data={item} selectedSeat={selectedSeat} setSelectedSeat={setSelectedSeat} />)}
                {round && seatListReturnWay.length > 0 && seatListReturnWay.map((item, index) => <ReturnSeat key={index} paxCount={paxCount} data={item} returnSelectedSeat={returnSelectedSeat} setReturnSelectedSeat={setReturnSelectedSeat} />)}
              </>
            ) : (
              <>
                {seatListOneWay.length > 0 && seatListOneWay.map((item, index) => <Seat key={index} paxCount={paxCount} data={item} selectedSeat={selectedSeat} setSelectedSeat={setSelectedSeat} />)}
                {seatListReturnWay.length > 0 && seatListReturnWay.map((item, index) => <ReturnSeat key={index} paxCount={paxCount} data={item} returnSelectedSeat={returnSelectedSeat} setReturnSelectedSeat={setReturnSelectedSeat} />)}
              </>
            )}
            {/* {(!round && seatListOneWay.length > 0) && seatListOneWay.map((item, index) => <Seat key={index} paxCount={paxCount} data={item} selectedSeat={selectedSeat} setSelectedSeat={setSelectedSeat} />)}
                        {(round && seatListReturnWay.length > 0) && seatListReturnWay.map((item, index) => <ReturnSeat key={index} paxCount={paxCount} data={item} returnSelectedSeat={returnSelectedSeat} setReturnSelectedSeat={setReturnSelectedSeat} />)} */}
          </div>
        </div>
        <div className="txt-a-r mt-1 d-f sm-f-a-c f-spb sm-f-col">
          <span className="b-500">Selected Seat: {!round ? (seatListOneWay.length > 0 ? selectedSeat.map((item) => item.seatNo).join(",") : returnSelectedSeat.map((item) => item.seatNo).join(",")) : returnSelectedSeat.map((item) => item.seatNo).join(",")}</span>
          <button onClick={handleConfirm} className="btn pointer">
            Confirm Seat
          </button>
        </div>
      </div>
      {loader && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {msg.message && <Success data={msg} />}
      {roundMsg && <SelectReturnSeatMsg setLoader={setLoader} setRound={setRound} setRoundMsg={setRoundMsg} />}
      {bookingSuccess && <BookingSuccess />}
    </div>
  );
}

function Seat({ paxCount, data, selectedSeat, setSelectedSeat }) {
  const [color, setColor] = useState(data.status ? (data.status == 3 ? "white" : BLUE) : GRAY);
  const [status, setStatus] = useState(1);
  function addSeat() {
    if (data.status) {
      if (status == 2) {
        setColor(BLUE);
        setStatus(1);
        const newSeat = selectedSeat.filter((item) => {
          return data.seatNo != item.seatNo;
        });
        setSelectedSeat(newSeat);
      } else {
        if (paxCount > selectedSeat.length) {
          setColor(GREEN);
          setStatus(2);
          setSelectedSeat([...selectedSeat, data]);
        }
      }
    }
  }
  return (
    <button onClick={addSeat} disabled={data.status == 3 ? true : false} className={`p-r seat-box sm-seat-box white d-f f-a-c f-j-c b-600 br-n ${data.status != 3 ? " pointer" : ""}`} style={{ opacity: color == "white" ? 0 : 1, backgroundColor: color, border: status == 2 ? "2px solid #4d4242" : "" }}>
      {data.seatNo}
      {status == 2 && (
        <div className={`${status == 2 ? "deselectSeat" : ""} d-f f-j-c f-a-c p-a`}>
          {" "}
          <span className="fs-12px">&#10005;</span>
        </div>
      )}
    </button>
  );
}
function ReturnSeat({ paxCount, data, returnSelectedSeat, setReturnSelectedSeat }) {
  const [color, setColor] = useState(data.status ? (data.status == 3 ? "white" : BLUE) : GRAY);
  const [status, setStatus] = useState(1);
  function showAlert() {
    if (data.status) {
      if (status == 2) {
        setColor(BLUE);
        setStatus(1);
        const newSeat = returnSelectedSeat.filter((item) => {
          return data.seatNo != item.seatNo;
        });
        setReturnSelectedSeat(newSeat);
      } else {
        if (paxCount > returnSelectedSeat.length) {
          setColor(GREEN);
          setStatus(2);
          setReturnSelectedSeat([...returnSelectedSeat, data]);
        }
      }
    }
  }
  const style = {
    box: {
      width: "48px",
      height: "48px",
      borderRadius: "4px",
    },
  };
  return (
    <button onClick={showAlert} disabled={data.status == 3 ? true : false} className={`p-r seat-box sm-seat-box white d-f f-a-c f-j-c b-600 br-n ${data.status != 3 ? " pointer" : ""}`} style={{ opacity: color == "white" ? 0 : 1, backgroundColor: color, border: status == 2 ? "2px solid #4d4242" : "" }}>
      {data.seatNo}
      {status == 2 && (
        <div className={`${status == 2 ? "deselectSeat" : ""} d-f f-j-c f-a-c p-a`}>
          {" "}
          <span className="fs-12px">&#10005;</span>
        </div>
      )}
    </button>
  );
}

function Success({ data }) {
  return (
    <div className="overlay">
      <div className="sm-malr-1 p-c p-1" style={{ maxWidth: "500px", height: "fit-content" }}>
        <div className="bg-white p-1 br-r-8 txt-a-c">
          <div className="txt-a-c">
            <img src={data.status == "success" ? successImg : failImg} alt="" width="80px" />
          </div>
          <p className="primary mt-1">{data.message}</p>
          <p className="gray mt-8px">{data.alertMessage}</p>
          {data.isBtn && (
            <button
              className="pointer msg-btn mt-1"
              onClick={() => {
                window.location.reload();
              }}
            >
              ok
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
function BookingSuccess({ data }) {
  return (
    <div className="overlay">
      <div className="sm-malr-1 p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="bg-white p-1 br-r-8 txt-a-c">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <p className="primary b-500 mt-1">Your eTicket has been booked successfully!</p>
          <p className="gray" style={{ marginTop: ".5rem" }}>
            Check your dashboard for the eTicket to Download.
          </p>
          <button
            className="pointer msg-btn mt-1"
            onClick={() => {
              localStorage.removeItem("bookingId");
              localStorage.removeItem("changeBookingId");
              window.location.assign("/");
            }}
          >
            ok
          </button>
        </div>
      </div>
    </div>
  );
}
function SelectReturnSeatMsg({ setRound, setRoundMsg, setLoader }) {
  return (
    <div className="overlay">
      <div className="sm-malr-1 p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="bg-white p-1 br-r-8 txt-a-c">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <p className="primary">You have successfully booked your Preferred Seats for Departure!</p>
          <p className="gray">Continue to Book your Preferred Seat for Return.</p>
          <button
            className="pointer msg-btn mt-1"
            onClick={() => {
              setRoundMsg(false);
              setLoader(true);
              setTimeout(() => {
                setRound(true);
                setLoader(false);
                window.scrollTo(0, 0);
              }, 3000);
            }}
          >
            ok
          </button>
        </div>
      </div>
    </div>
  );
}
// import seat from '../../assets/images/seat.png';
// import { Link } from "react-router-dom";
// export default function SeatSelection() {
//     const style = {
//         wrapper: {
//             width: '512px',
//             height: '360px',
//             boxShadow: ' 0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
//             borderRadius: "1rem"
//         },
//         btn: {
//             width: "196px",
//             height: "48px",
//             textDecoration: 'none',
//             border: '1px solid var(--primaryColor)',
//         }
//     }
//     return (
//         <div>
//             <h1 className="fs-20px b-500">Select Preferred Seat (Optional)</h1>
//             <div className="mt-2 d-f f-spb f-col" style={style.wrapper}>
//                 <img src={seat} alt="" />
//                 <div className="d-f f-col p1-5 r-gap-1-5">
//                     <p>Make your journey uniquely yours by choosing the perfect seats for your comfort and enjoyment.</p>
//                     <Link className="b-500 d-f f-a-c f-j-c primary br-r-8" style={style.btn} >Select Seat</Link>
//                 </div>
//             </div>
//         </div>
//     )
// }
