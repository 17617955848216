import { Link } from "react-router-dom";
import mealMob from "../../assets/images/mealSelectionMobile.png";
import mealImg from "../../assets/images/mealSelection.png";
import food from "../../assets/images/meal.png";
import successImg from "../../assets/images/success.png";
import returnImg from "../../assets/images/return.png";
import warningImg from "../../assets/images/warning.png";
import { useEffect, useState, useContext } from "react";
import { getMeals, bookFood, getFood } from "../../api/api";
import Loader from "../icons/Loader";
import { Roundcontext } from "../ProcessContext";
import { ProcessContext } from "../ProcessContext";
import { useMediaQuery } from "react-responsive";
export default function MealSelection({ totalMeal, setTotalMeal, data, travelDate, proceedPaymentDts, setProceedPaymentDts }) {
  const isMob = useMediaQuery({ query: "(max-width:1024px)" });
  const isDesk = useMediaQuery({ query: "(min-width:1025px)" });
  const [show, setShow] = useState(false);
  const [mealInfo, setMealInfo] = useState({
    oneWayAmount: 0,
    roundWayAmount: 0,
  });
  const [currentMealInfo, setCurrentMealInfo] = useState({
    oneWayAmount: 0,
    roundWayAmount: 0,
  });
  const chargeCurrency = data.oneWayData.chargeCurrency ? data.oneWayData.chargeCurrency : "LKR";
  const [totalMeals, setTotalMeals] = useState([]);
  const [mealTotalPrice, setMealTotalPrice] = useState(0);
  const [isRemoved, setIsRemoved] = useState(false);
  const style = {
    wrapper: {
      maxWidth: "512px",
      height: "fit-content",
      boxShadow: " 0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "1rem",
      overflow: "hidden",
    },
    btn: {
      width: "196px",
      height: "48px",
      textDecoration: "none",
      border: "1px solid var(--primaryColor)",
    },
  };
  return (
    <div>
      <h1 className="fs-20px b-500">Select Preferred Meal (Optional)</h1>
      <div className="mt-2 d-f f-spb f-col" style={style.wrapper}>
        <img src={isDesk ? mealImg : mealMob} alt="" width="100%" height="192px" />
        <div className="d-f f-col p1-5 r-gap-1-5">
          <p>Indulge in a tailored culinary experience. Select your preferred meal and savor every moment.</p>
          {/* {!(mealInfo.oneWayAmount + mealInfo.roundWayAmount) && (
            <button onClick={() => setShow(true)} className="sm-m-a pointer br-btn b-500 d-f f-a-c f-j-c primary br-r-8" style={style.btn}>
              Select Meal
            </button>
          )} */}
          {totalMeal.amount + totalMeal.returnAmount === 0 && (
            <button onClick={() => setShow(true)} className="sm-m-a pointer br-btn b-500 d-f f-a-c f-j-c primary br-r-8" style={style.btn}>
              Select Meal
            </button>
          )}
        </div>
        {totalMeal.amount + totalMeal.returnAmount > 0 && <Detail totalMeal={totalMeal} setTotalMeal={setTotalMeal} setIsRemoved={setIsRemoved} mealTotalPrice={mealTotalPrice} setMealTotalPrice={setMealTotalPrice} chargeCurrency={chargeCurrency} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} setTotalMeals={setTotalMeals} totalMeals={totalMeals} data={data} mealInfo={mealInfo} setMealInfo={setMealInfo} setShow={setShow} />}
      </div>
      <div className="overlay" style={{ display: show ? "block" : "none", top: isMob ? "30px" : 0 }}>
        <Meals totalMeal={totalMeal} setTotalMeal={setTotalMeal} mealTotalPrice={mealTotalPrice} setMealTotalPrice={setMealTotalPrice} currentMealInfo={currentMealInfo} setCurrentMealInfo={setCurrentMealInfo} travelDate={travelDate} chargeCurrency={chargeCurrency} isRound={data.round} totalMeals={totalMeals} setTotalMeals={setTotalMeals} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} setShow={setShow} setMealInfo={setMealInfo} mealInfo={mealInfo} />
      </div>
      {isRemoved && <Success setIsRemoved={setIsRemoved} />}
    </div>
  );
}

function Meals({ totalMeal, setTotalMeal, setMealTotalPrice, currentMealInfo, setCurrentMealInfo, travelDate, chargeCurrency, totalMeals, setTotalMeals, setShow, isRound, mealInfo, setMealInfo, proceedPaymentDts }) {
  const isMob = useMediaQuery({ query: "(max-width:1024px)" });
  const [menuType, setMenuType] = useState([]);
  const [meals, setMeals] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedSubMenu, setSelectedSubMenu] = useState(0);
  const [subMenu, setSubMenu] = useState([]);
  const [success, setSuccess] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState([]);
  const [maxMealsCount, setMaxMealsCount] = useState(0);
  const [countMeals, setCountMeals] = useState(0);
  const [msg, setMsg] = useState(false);
  const bookingId = proceedPaymentDts.bookingId;
  function filterMeals(id, subId, lists) {
    const filteredMeals = lists.filter((item) => {
      if (id == item.majorGroupCode && subId == item.subGroupCode) {
        return item;
      }
    });
    setSelectedSubMenu(subId);
    setMeals(filteredMeals);
  }
  useEffect(() => {
    getMeals(travelDate, proceedPaymentDts.bookingId).then((data) => {
      if (data) {
        getFood({
          companyCode: bookingId[0],
          documentCode: bookingId[1],
          bookingYear: bookingId[2],
          bookingSerial: bookingId[3],
        }).then((foodData) => {
          if (foodData) {
            data.menu.forEach((data) => {
              if (foodData.bookedFood.food) {
                foodData.bookedFood.food.forEach((food) => {
                  if (data.menuItemId === food.code) data.oneWayQty = food.quantity;
                });
              }
              if (foodData.bookedFood.returnFood) {
                foodData.bookedFood.returnFood.forEach((food) => {
                  if (data.menuItemId === food.code) data.returnQty = food.quantity;
                });
              }
            });
            setTotalMeal({
              amount: foodData.totalMeal.amount,
              returnAmount: foodData.totalMeal.returnAmount,
            });
            setMaxMealsCount(data.maxMenuCount);
            setMenuType(data.menuCategory);
            setSubMenu(data.subMenu);
            setTotalMeals(data.menu);
            const newArrayMeal = data.menu.map((item) => ({ ...item, oneWayQtyDec: 0, returnQtyDec: 0 }));
            setSelectedMeal(newArrayMeal);
            filterMeals(data.menuCategory[0].majorGroupCode, data.subMenu[0].subGroupCode, data.menu);
            setSelectedMenu(data.menuCategory[0].majorGroupCode);
            setSuccess(false);
          }
        });
        // setMaxMealsCount(data.maxMenuCount);
        // setMenuType(data.menuCategory);
        // setSubMenu(data.subMenu);
        // setTotalMeals(data.menu);
        // const newArrayMeal = data.menu.map((item) => ({ ...item, oneWayQtyDec: 0, returnQtyDec: 0 }));
        // setSelectedMeal(newArrayMeal);
        // filterMeals(data.menuCategory[0].majorGroupCode, data.subMenu[0].subGroupCode, data.menu);
        // setSelectedMenu(data.menuCategory[0].majorGroupCode);
        // setSuccess(false);
      }
    });
  }, []);
  function handleConfirm() {
    setCurrentMealInfo({ oneWayAmount: 0, roundWayAmount: 0 });
    setSubmit(true);
    setTimeout(() => {
      let foodAmount = 0;
      let returnFoodAmount = 0;
      const food = [];
      const returnFood = [];
      totalMeals.map((item) => {
        if (item.oneWayQty > 0) {
          food.push({ Code: item.menuItemId, Quantity: item.oneWayQty });
          foodAmount += item.oneWayQty * item.price;
        }
        if (item.returnQty > 0) {
          returnFood.push({ Code: item.menuItemId, Quantity: item.returnQty });
          returnFoodAmount += item.returnQty * item.price;
        }
      });
      if (isRound) {
        setMealTotalPrice(foodAmount + returnFoodAmount);
      } else {
        setMealTotalPrice(foodAmount + returnFoodAmount);
      }
      selectedMeal.forEach((item) => {
        item.oneWayQty = 0;
        item.returnQty = 0;
        item.oneWayQtyDec = 0;
        item.returnQtyDec = 0;
        return item;
      });
      bookFood({
        food: food,
        returnFood: returnFood,
        paxNumber: {
          companyCode: bookingId[0],
          documentCode: bookingId[1],
          bookingYear: bookingId[2],
          bookingSerial: bookingId[3],
          seqNo: 1,
        },
        requestType: "string",
      }).then((data) => {
        if (data) {
          setTotalMeal({ amount: data.totalMeal.amount, returnAmount: data.totalMeal.returnAmount });
        }
      });

      setShow(false);
      setSubmit(false);
    }, 2000);
  }
  // function handleConfirm() {
  //   setCurrentMealInfo({ oneWayAmount: 0, roundWayAmount: 0 });
  //   setSubmit(true);

  //   setTimeout(() => {
  //     let foodAmount = 0;
  //     let returnFoodAmount = 0;
  //     const food = [];
  //     const returnFood = [];
  //     totalMeals.map((item) => {
  //       if (item.oneWayQty > 0) {
  //         food.push({ Code: item.menuItemId, Quantity: item.oneWayQty });
  //         foodAmount += item.oneWayQty * item.price;
  //       }
  //       if (item.returnQty > 0) {
  //         returnFood.push({ Code: item.menuItemId, Quantity: item.returnQty });
  //         returnFoodAmount += item.returnQty * item.price;
  //       }
  //     });
  //     if (isRound) {
  //       proceedPaymentDts.paymentAmount -= mealTotalPrice;
  //       setProceedPaymentDts({
  //         ...proceedPaymentDts,
  //         food: food,
  //         returnFood: returnFood,
  //         paymentAmount: proceedPaymentDts.paymentAmount + foodAmount + returnFoodAmount,
  //       });
  //       setMealTotalPrice(foodAmount + returnFoodAmount);
  //     } else {
  //       proceedPaymentDts.paymentAmount -= mealTotalPrice;
  //       setProceedPaymentDts({
  //         ...proceedPaymentDts,
  //         food: food,
  //         paymentAmount: proceedPaymentDts.paymentAmount + foodAmount,
  //       });
  //       setMealTotalPrice(foodAmount + returnFoodAmount);
  //     }
  //     selectedMeal.forEach((item) => {
  //       item.oneWayQty = 0;
  //       item.returnQty = 0;
  //       item.oneWayQtyDec = 0;
  //       item.returnQtyDec = 0;
  //       return item;
  //     });
  //     setShow(false);
  //     setSubmit(false);
  //   }, 2000);
  // }
  function handleCancel() {
    setMealInfo({
      oneWayAmount: mealInfo.oneWayAmount - currentMealInfo.oneWayAmount,
      roundWayAmount: mealInfo.roundWayAmount - currentMealInfo.roundWayAmount,
    });
    setCurrentMealInfo({ oneWayAmount: 0, roundWayAmount: 0 });
    if (selectedMeal.length) {
      selectedMeal.forEach((item) => {
        totalMeals.forEach((meal) => {
          if (meal.menuItemId == item.menuItemId) {
            if (item.oneWayQty + item.oneWayQtyDec) {
              meal.oneWayQty -= item.oneWayQty - item.oneWayQtyDec;
              item.oneWayQty = 0;
              item.oneWayQtyDec = 0;
            }
            if (item.returnQty + item.returnQtyDec) {
              meal.returnQty -= item.returnQty - item.returnQtyDec;
              item.returnQty = 0;
              item.returnQtyDec = 0;
            }
          }
        });
      });
    }
    setShow(false);
  }
  // function handleCancel() {
  //   setMealInfo({
  //     oneWayAmount: mealInfo.oneWayAmount - currentMealInfo.oneWayAmount,
  //     roundWayAmount: mealInfo.roundWayAmount - currentMealInfo.roundWayAmount,
  //   });
  //   setCurrentMealInfo({ oneWayAmount: 0, roundWayAmount: 0 });
  //   if (selectedMeal.length) {
  //     selectedMeal.forEach((item) => {
  //       totalMeals.forEach((meal) => {
  //         if (meal.menuItemId == item.menuItemId) {
  //           if (item.oneWayQty + item.oneWayQtyDec) {
  //             meal.oneWayQty -= item.oneWayQty - item.oneWayQtyDec;
  //             item.oneWayQty = 0;
  //             item.oneWayQtyDec = 0;
  //           }
  //           if (item.returnQty + item.returnQtyDec) {
  //             meal.returnQty -= item.returnQty - item.returnQtyDec;
  //             item.returnQty = 0;
  //             item.returnQtyDec = 0;
  //           }
  //         }
  //       });
  //     });
  //   }
  //   setShow(false);
  // }
  return (
    <div id="meals" className="bg-white p-c p-1 w-full smd-h-100 smd-d-f smd-f-col r-gap-1 br-r-1" style={{ maxWidth: "1110px", height: "fit-content" }}>
      {!isMob && (
        <div className="d-f c-gap-1 f-wrap r-gap-1">
          {menuType.length > 0 &&
            menuType.map((menuItem, index) => (
              <button
                onClick={() => {
                  setSelectedMenu(menuItem.majorGroupCode);
                  const getSub = subMenu.find((item) => item.majorGroupCode == menuItem.majorGroupCode).subGroupCode;
                  setSelectedSubMenu(getSub);
                  filterMeals(menuItem.majorGroupCode, getSub, totalMeals);
                }}
                className={`pointer mealBtn b-500 ${menuItem.majorGroupCode == selectedMenu ? "bg-primary white" : "input-gray"}`}
                key={menuItem.majorGroupCode}
              >
                {menuItem.majorGroupDesc}
              </button>
            ))}
        </div>
      )}
      {!isMob && (
        <div className="d-f c-gap-1 f-wrap r-gap-1 mt-1">
          {subMenu.length > 0 &&
            subMenu.map((item, index) => {
              if (item.majorGroupCode == selectedMenu) {
                return (
                  <span
                    onClick={() => {
                      setSelectedSubMenu(item.subGroupCode);
                      filterMeals(item.majorGroupCode, item.subGroupCode, totalMeals);
                    }}
                    className={`pointer b-500 ${selectedSubMenu == item.subGroupCode ? "primary" : "black"}`}
                    key={item.subGroupCode}
                  >
                    {item.subGroupDesc}
                  </span>
                );
              }
            })}
        </div>
      )}
      {isMob && (
        <div className="d-f c-gap-1">
          <div className="w-full">
            <label htmlFor="" className="fs-12px">
              Category
            </label>
            <select
              className="search-input"
              onChange={(e) => {
                setSelectedMenu(e.target.value);
                const getSub = subMenu.find((item) => item.majorGroupCode == e.target.value).subGroupCode;
                setSelectedSubMenu(getSub);
                filterMeals(e.target.value, getSub, totalMeals);
              }}
            >
              {menuType.length > 0 &&
                menuType.map((menuItem, index) => (
                  <option key={menuItem.majorGroupCode} value={menuItem.majorGroupCode}>
                    {menuItem.majorGroupDesc}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full">
            <label htmlFor="" className="fs-12px">
              Sub Category
            </label>
            <select
              className="search-input"
              onChange={(e) => {
                setSelectedSubMenu(e.target.value);
                filterMeals(selectedMenu, e.target.value, totalMeals);
              }}
            >
              {subMenu.length > 0 &&
                subMenu.map((item, index) => {
                  if (item.majorGroupCode == selectedMenu) {
                    return (
                      <option key={item.subGroupCode} value={item.subGroupCode}>
                        {item.subGroupDesc}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        </div>
      )}
      <div className="d-f f-j-e mt-1">
        <p className=" b-500 smd-m-a input-gray" style={{ padding: ".5rem", borderRadius: ".5rem" }}>
          {/* Total Price: <span style={{ fontFamily: "Arial, sans-serif" }}> {(mealInfo.oneWayAmount + mealInfo.roundWayAmount).toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span> */}
          Total Price: <span style={{ fontFamily: "Arial, sans-serif" }}> {totalMeal.amount.toLocaleString("en-GB", { style: "currency", currencyDisplay: "code", currency: chargeCurrency })}</span>
        </p>
      </div>
      <div className="mt-1 smd-m-0 smd-f-grow" style={{ height: "400px", overflowY: "auto" }}>
        {success && <Loader color="blue" />}
        <div className="d-f c-gap-8 r-gap-8 smd-r-gap-1 mb-2 f-wrap p-1 sm-p-1px">{meals.length > 0 && meals.map((item) => <Meal key={item.menuItemId} totalMeals={totalMeals} setMsg={setMsg} maxMealsCount={maxMealsCount} countMeals={countMeals} setCountMeals={setCountMeals} currentMealInfo={currentMealInfo} setCurrentMealInfo={setCurrentMealInfo} selectedMeal={selectedMeal} setSelectedMeal={setSelectedMeal} data={item} mealInfo={mealInfo} setMealInfo={setMealInfo} meals={meals} setMeals={setMeals} />)}</div>
      </div>
      <div className="txt-end mt-1 smd-d-f smd-c-gap-1 f-j-c smd-m-0">
        <button className="pointer br-btn mr-2 sm-m-0 " onClick={handleCancel}>
          Cancel
        </button>
        <button className="p-r pointer btn" style={{ opacity: submit ? 0.4 : 1 }} onClick={handleConfirm}>
          Confirm {submit && <Loader color="black" />}
        </button>
      </div>
      {msg && <Msg setMsg={setMsg} maxMealsCount={maxMealsCount} />}
    </div>
  );
}

function Meal({ totalMeals, setMsg, countMeals, maxMealsCount, setCountMeals, selectedMeal, setSelectedMeal, currentMealInfo, setCurrentMealInfo, data, mealInfo, setMealInfo, meals, setMeals, setTotalPrice, totalPrice }) {
  const isRound = useContext(Roundcontext);
  const [add, setAdd] = useState(false);
  const [qty, setQty] = useState({
    oneWayQty: 0,
    returnQty: 0,
  });
  const style = {
    btn: {
      width: "144px",
      height: "40px",
      borderRadius: "24px",
    },
    roundBtn: {
      border: "none",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  const mealData = {
    chargecurrency: data.chargecurrency,
    majorGroupCode: data.majorGroupCode,
    menuItemId: data.menuItemId,
    menuItemName: data.menuItemName,
    price: data.price,
    subGroupCode: data.subGroupCode,
    oneWayQtyDec: 0,
    returnQtyDec: 0,
    oneWayQty: 0,
    returnQty: 0,
  };
  function addSelectedMeal(type, id) {
    selectedMeal.forEach((item) => {
      if (item.menuItemId == id) {
        item[type] += 1;
      }
    });
  }
  function removeSelectedMeal(type, id) {
    selectedMeal.forEach((item) => {
      if (item.menuItemId == id) item[type + "Dec"] += 1;
    });
  }
  function increament(val, id, price, type) {
    const amountType = type == "oneWayQty" ? "oneWayAmount" : "roundWayAmount";
    // const currentAmountType = type == "oneWayQty" ? "oneWayAmount" : "roundWayAmount";
    // setCurrentMealInfo({ ...currentMealInfo, [amountType]: currentMealInfo[amountType] + (val * price) })
    // setMealInfo({ ...mealInfo, [amountType]: mealInfo[amountType] + (val * price) })
    const newArray = meals.map((item) => {
      if (item.menuItemId == id) {
        if (checkMaxMeals(id)) {
          setCurrentMealInfo({ ...currentMealInfo, [amountType]: currentMealInfo[amountType] + val * price });
          setMealInfo({ ...mealInfo, [amountType]: mealInfo[amountType] + val * price });
          item[type] = item[type] + val;
          addSelectedMeal(type, id);
        } else setMsg(true);
        return item;
      } else return item;
    });
    // addSelectedMeal(type, id);
    setMeals(newArray);

    // let newObj = "";
    // if (selectedMeal.length) {
    //     console.log(selectedMeal)
    //     selectedMeal.forEach(item => {
    //         if (item.menuItemId == id) {
    //             console.log(item.menuItemId, id)
    //             item[type] += 1
    //             setQty({ ...qty, [type]: qty[type] + 1 })
    //         }
    //         else {
    //             if (qty[type] == 0) {
    //                 console.log(qty[type], type, id, item.menuItemId)
    //                 newObj = { ...mealData, [type]: 1 }
    //                 setQty({ ...qty, [type]: 1 })
    //             }

    //         }
    //     });
    //     if (newObj) selectedMeal.push(newObj)
    //     console.log(newObj)
    // } else {
    //     selectedMeal.push({ ...mealData, [type]: 1 })
    //     setQty({ ...qty, [type]: qty[type] + 1 })
    // }
    // console.log(selectedMeal)
  }
  function decreament(val, id, price, type) {
    const amountType = type == "oneWayQty" ? "oneWayAmount" : "roundWayAmount";
    const currentAmountType = type == "oneWayQty" ? "oneWayAmount" : "roundWayAmount";
    const newArray = meals.map((item) => {
      if (item.menuItemId == id) {
        if (item[type] > 0) {
          item[type] = item[type] - val;
          setMealInfo({ ...mealInfo, [amountType]: mealInfo[amountType] - val * price });
          setCurrentMealInfo({ ...currentMealInfo, [amountType]: currentMealInfo[amountType] - val * price });
          removeSelectedMeal(type, id);
        } else {
          item[type] = 0;
        }
        return item;
      } else return item;
    });
    setMeals(newArray);
  }
  function checkMaxMeals(id) {
    const maxMeals = [];
    totalMeals.forEach((meal) => {
      if (meal.oneWayQty + meal.returnQty > 0) {
        maxMeals.push(meal);
      }
    });
    if (!maxMeals.length) {
      return true;
    } else if (maxMeals.length <= maxMealsCount) {
      if (maxMeals.length == maxMealsCount) {
        let check = false;
        maxMeals.forEach((meal) => {
          if (meal.menuItemId == id) {
            check = true;
          }
        });
        return check;
      } else return true;
    } else {
      return false;
    }
  }
  function remove(id, meal) {
    let check = false;
    const newArray = selectedMeal.map((meal) => {
      if (meal.menuItemId == id) {
        check = true;
        setMealInfo({ ...mealInfo, oneWayAmount: mealInfo.oneWayAmount - meal.oneWayQty * meal.price, roundWayAmount: mealInfo.roundWayAmount - meal.returnQty * meal.price });
        setCurrentMealInfo({ ...currentMealInfo, oneWayAmount: mealInfo.oneWayAmount - meal.oneWayQty * meal.price, roundWayAmoun: mealInfo.roundWayAmount - meal.returnQty * meal.price });
        meal.oneWayQty = 0;
        meal.returnQty = 0;
        meal.oneWayQtyDec = 0;
        meal.returnQtyDec = 0;
      }
      return meal;
    });
    const newArray2 = meals.map((meal) => {
      if (meal.menuItemId == id) {
        meal.oneWayQty = 0;
        meal.returnQty = 0;
        meal.oneWayQtyDec = 0;
        meal.returnQtyDec = 0;
      }
      return meal;
    });
    setMeals(newArray2);
    setSelectedMeal(newArray);
  }
  return (
    <div className={`br-r-12 fade d-f f-col f-spb md-w-full smd-f-a-c smd-p-5px ${isRound ? "meal-round" : "meal"}`} style={{ overflow: "hidden", width: "232px", height: "fit-content", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}>
      <img src={food} alt="" width="100%" height="123px" />
      <div className="d-f f-spb f-col p-1 smd-f-row smd-f-a-c smd-p-0 mealDesWrapper" style={{ flexGrow: 1 }}>
        <div className="mealNameWrapper">
          <p style={{ height: "24px", overflow: "hidden" }}>{data.menuItemName}</p>
          <p className="b-500" style={{ fontFamily: "Arial, sans-serif" }}>
            {data.price.toLocaleString("en-GB", { style: "currency", currency: data.chargecurrency })}
          </p>
        </div>
        <div className="m-a mt-1 smd-m-0" style={{ width: "fit-content" }}>
          {!data.oneWayQty && !add && (
            <button onClick={() => setAdd(true)} className="bg-primary br-n white pointer mealAddBtn" style={style.btn}>
              Add
            </button>
          )}
          {(data.oneWayQty || add) && (
            <>
              <div className="fade d-f f-a-c mealAddBtn2" style={{ justifyContent: "space-around", border: "1px solid var(--primaryColor)", width: "144px", height: "40px", borderRadius: "24px" }}>
                <button onClick={() => decreament(1, data.menuItemId, data.price, "oneWayQty")} className="pointer bg-primary white" style={style.roundBtn}>
                  -
                </button>
                <span>{data.oneWayQty}</span>
                <button onClick={() => increament(1, data.menuItemId, data.price, "oneWayQty")} className="pointer bg-primary white" style={style.roundBtn}>
                  +
                </button>
              </div>
            </>
          )}
          {(data.oneWayQty || data.returnQty || add) && isRound && (
            <>
              <div className="mt-1 sm-mt-8px">Return</div>
              <div className="fade d-f f-a-c mealAddBtn2" style={{ justifyContent: "space-around", border: "1px solid var(--primaryColor)", width: "144px", height: "40px", borderRadius: "24px" }}>
                <button onClick={() => decreament(1, data.menuItemId, data.price, "returnQty")} className="pointer bg-primary white" style={style.roundBtn}>
                  -
                </button>
                <span>{data.returnQty}</span>
                <button onClick={() => increament(1, data.menuItemId, data.price, "returnQty")} className="pointer bg-primary white" style={style.roundBtn}>
                  +
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function Detail({ totalMeal, setTotalMeal, setIsRemoved, chargeCurrency, proceedPaymentDts, totalMeals, data, mealInfo, setShow }) {
  const [removeLoader, setRemoveLoader] = useState(false);
  const [removeType, setRemoveType] = useState("");
  const bookingId = proceedPaymentDts.bookingId;
  // function handleRemove(type) {
  //   setRemoveType(type);
  //   setRemoveLoader(true);
  //   let foodAmount = 0;
  //   let returnFoodAmount = 0;
  //   setTimeout(() => {
  //     if (type == "oneWay") {
  //       totalMeals.map((item) => {
  //         if (item.oneWayQty > 0) {
  //           foodAmount += item.oneWayQty * item.price;
  //         }
  //       });
  //     } else {
  //       totalMeals.map((item) => {
  //         if (item.returnQty > 0) {
  //           returnFoodAmount += item.returnQty * item.price;
  //         }
  //       });
  //     }
  //     const foodType = type == "oneWay" ? "food" : "returnFood";
  //     setProceedPaymentDts({
  //       ...proceedPaymentDts,
  //       paymentAmount: proceedPaymentDts.paymentAmount - (foodAmount + returnFoodAmount),
  //       [foodType]: [],
  //     });
  //     setMealInfo({ ...mealInfo, [type + "Amount"]: 0 });
  //     const mealType = type == "oneWay" ? "oneWayQty" : "returnQty";
  //     const newTotalMeals = totalMeals.map((item) => {
  //       item[mealType] = 0;
  //       return item;
  //     });
  //     setMealTotalPrice(mealTotalPrice - (foodAmount + returnFoodAmount));
  //     setTotalMeals(newTotalMeals);
  //     setRemoveLoader(false);
  //     setIsRemoved(true);
  //   }, 2000);
  // }
  function handleRemove(type) {
    setRemoveType(type);
    setRemoveLoader(true);
    setTimeout(() => {
      getFood({
        companyCode: bookingId[0],
        documentCode: bookingId[1],
        bookingYear: bookingId[2],
        bookingSerial: bookingId[3],
      }).then((data) => {
        if (data) {
          bookFood({
            food: type == "oneWay" ? [] : data.bookedFood.food,
            returnFood: type == "roundWay" ? [] : data.bookedFood.returnFood,
            paxNumber: {
              companyCode: bookingId[0],
              documentCode: bookingId[1],
              bookingYear: bookingId[2],
              bookingSerial: bookingId[3],
              seqNo: 1,
            },
            requestType: "string",
          }).then((data) => {
            if (data) {
              setTotalMeal({ amount: data.totalMeal.amount, returnAmount: data.totalMeal.returnAmount });
              setRemoveLoader(false);
              setIsRemoved(true);
            }
          });
        }
      });
    }, 2000);
  }
  const [showOrderTray, setShowOrderTray] = useState("");
  return (
    <div className="p-1">
      {totalMeal.amount > 0 && (
        <>
          {" "}
          <hr style={{ opacity: ".2" }} />
          <div className="d-f f-spb ptb-1 f-end">
            <div className="d-f f-col">
              <p className="primary">
                {data.oneWayData.voyageFrom} to {data.oneWayData.voyageTo}
              </p>
              {totalMeal.amount > 0 && (
                <p onClick={() => setShowOrderTray("one")} className="primary b-700 pointer" style={{ textDecoration: "underline" }}>
                  Order Tray
                </p>
              )}
              <p>
                Total:{" "}
                <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
                  {totalMeal.amount.toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}
                </span>
              </p>
            </div>
            <div className="p-r txt-dec-u pointer" onClick={() => handleRemove("oneWay")} style={{ minWidth: "35px", minHeight: "35px" }}>
              {/* {removeType == "oneWay" && removeLoader ? <Loader color="#DF7435" /> : "Remove"} */}
            </div>
          </div>
        </>
      )}
      {totalMeal.returnAmount > 0 && (
        <>
          <hr style={{ opacity: ".2" }} />
          <div className="d-f f-spb ptb-1 f-end">
            <div className="d-f f-col">
              <p className="primary">
                {data.roundData.voyageFrom} to {data.roundData.voyageTo}
              </p>
              {totalMeal.returnAmount > 0 && (
                <p onClick={() => setShowOrderTray("two")} className="primary b-700 pointer" style={{ textDecoration: "underline" }}>
                  Order Tray
                </p>
              )}
              <p>
                Total:{" "}
                <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
                  {totalMeal.returnAmount.toLocaleString("en-GB", { style: "currency", currency: data.roundData.chargeCurrency })}
                </span>
              </p>
            </div>
            <div className="p-r txt-dec-u pointer" onClick={() => handleRemove("roundWay")} style={{ minWidth: "35px", minHeight: "35px" }}>
              {/* {removeType == "roundWay" && removeLoader ? <Loader color="#DF7435" /> : "Remove"} */}
            </div>
          </div>
        </>
      )}
      <div className="d-f f-spb f-end">
        <button onClick={() => setShow(true)} className="pointer btn">
          Change
        </button>
        <p className="txt-end">
          Total Fare: <span className="b-600">{(totalMeal.amount + totalMeal.returnAmount).toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}</span>
        </p>
      </div>
      {showOrderTray && (
        <div onClick={() => setShowOrderTray("")} className="overlay">
          <OrderTray proceedPaymentDts={proceedPaymentDts} chargeCurrency={chargeCurrency} totalMeals={totalMeals} showOrderTray={showOrderTray} mealInfo={mealInfo} />
        </div>
      )}
    </div>
  );
}

function OrderTray({ chargeCurrency, totalMeals, proceedPaymentDts, showOrderTray, mealInfo }) {
  const food = [];
  const type = showOrderTray == "one" ? "food" : "returnFood";
  const travelType = showOrderTray == "one" ? "oneWayQty" : "returnQty";
  const tax = showOrderTray == "one" ? mealInfo.oneWayAmount : mealInfo.roundWayAmount;
  let totalPrice = 0;
  totalMeals.forEach((meal) => {
    if (meal[travelType] > 0) {
      totalPrice += meal.price * meal[travelType];
      food.push(meal);
    }
  });
  return (
    <div className="p-1 p-c" style={{ height: "fit-content" }}>
      <div className="p-8 bg-white br-r-12 m-a" style={{ maxWidth: "722px", maxHeight: "432px", overflow: "auto" }}>
        <div className="d-f f-spb">
          <p>Meal</p>
          <p>Price</p>
        </div>
        <hr />
        {food.map((item) => (
          <div key={item.menuItemId + showOrderTray}>
            <div className="d-f f-spb" style={{ borderBottom: "1px solid black", padding: ".5rem 0" }}>
              <span>
                {item.menuItemName} x {item[travelType]}
              </span>
              <span>{(item.price * item[travelType]).toLocaleString("en-GB", { style: "currency", currency: item.chargecurrency })}</span>
            </div>
            <hr />
          </div>
        ))}
        <div className="d-f f-spb mt-1">
          <span>Total Price</span>
          <span>{totalPrice.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span>
        </div>
        <hr />
        <hr />
      </div>
    </div>
  );
}

function Success({ setIsRemoved }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">You have successfully cancelled Pre-Order Meals!</p>
            <p>Please continue your booking!</p>
            <button className="pointer btn mt-1" onClick={() => setIsRemoved(false)} style={{ width: "120px" }}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function Msg({ setMsg, maxMealsCount }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={warningImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">Maximum Meal Types Reached!</p>
            <p className="gray">Your ticket has reached the allocated maximum meals selections:({maxMealsCount}) for passengers.</p>
            <button className="pointer btn mt-1" onClick={() => setMsg(false)} style={{ width: "120px" }}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
