// export default function Verify() {
//     return (
//         <div className="p-c br-r-12 d-f f-col shadow p-8" style={{ width: '416px', height: "406px" }}>
//             <h1 className="primary fs-20px b-500 m-b-1">verify your identity</h1>
//             <p className="gray mb-1-5">Please provide the security <br />
//                 code sent to your provided email address <br />
//                 to verify.
//             </p>
//             <label className="b-500" htmlFor="">Security Code</label><br />
//             <input className="input pl-1-5 mt-8px m-b-1" type="text" name="code" placeholder="Security Code" />
//             <span className="link">Resend Code</span>
//             <div className="txt-a-c">
//                 <button className="btn mt-2">Verify</button>
//             </div>
//         </div>
//     )
// }
import { useLocation, useNavigate } from "react-router-dom";
export default function Verify() {
  const navigate = useNavigate();
  const { state } = useLocation();
  if (!state) return navigate("/home");
  return (
    <div className="overlay remove-overlay">
      <div className="p-c br-r-12 d-f f-col sm-p-1-5" style={{ maxWidth: "416px", height: "fit-content" }}>
        <div className="bg-white p-8 sm-p-1 br-r-12 show-boxshadow">
          <h1 className="primary fs-20px b-500 mb-1 sm-txt-c">Verify your identity</h1>
          <p className="gray sm-txt-c">Kindly follow the provided confirmation link to your email to finalize the verification process and ensure the security of your account.</p>
          <div className="txt-a-c">
            <button className="btn mt-2 pointer" onClick={() => navigate("/")}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
